@import "breakpoints";

.card__container {
    position: relative;
    margin: 0 auto;
    padding: 1rem;

    border: 2px solid var(--colour-border);
    border-radius: 6px;
    box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    background: white;
}

.card {
    &--sales {
        background-color: var(--color-primary-background);
        border-color: var(--colour-border-darkened);

        .toggle-container:hover:not(.small, .tiny) {
            background-color: var(--colour-hover-darkened);
        }

        .toggle-button {
            border-color: var(--colour-border-darkened);
        }

        &-warn {
            background-color: var(--colour-yellow-background);
            border-color: var(--colour-border-yellow);
        }
    }
}
