.badge {
    display: inline-block;
    padding: 0.33em 0.5em;
    font-size: max(10px, 75%) !important;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    border-radius: 0.375rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    vertical-align: middle;
    width: min-content;
}

.badge--success {
    color: #00d97e;
    background-color: #ccf7e5;
}

.badge--info {
    color: #39afd1;
    background-color: #d7eff6;
}

.badge--warning {
    color: #f6c343;
    background-color: #fdf3d9
}

.badge--danger {
    color: #e63757;
    background-color: #fad7dd;
}

.badge--bashful {
    color: #8d8d8d;
    background-color: var(--colour-bashful);
}
