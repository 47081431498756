#__tms {
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

    @import "../legacy/loading-indicators";
    @import "../shared/layout";
    @import "../shared/previews";
    @import "../shared/collapsable";
    @import "../shared/keyframes";
    @import "../shared/checkbox";
    @import "../shared/alerts";
    @import "../shared/cards";
    @import "./backgrounds";
    @import "./typography";
    @import "./form";
    @import "./inputs";
    @import "./buttons";
    @import "./sections";
    @import "./badges";
    @import "./lists";

    display: grid;
    grid-template-columns: 250px 1fr;
    width: 100%;
    height: 100vh;

    /************************/
    /****** Variables ******/
    // Colours
    --colour-primary: #2c7be5;
    --colour-muted: #95aac9;
    --colour-disabled: #e9ecef;
    --colour-disabled-alt: #dce0e3;
    --colour-disabled-font: #6c7177;
    --colour-border: #D7DFE9;
    --colour-tooltip: var(--colour-secondary);
    --colour-emphasis: var(--colour-primary);

    --background-colour-code: #2a2d30;
    --border-colour-code: #3c3e42;

    // Fonts
    --default-font-colour: #12263f;
    --default-code-colour: #db842a;
    --default-font-family: "Cerebri Sans", sans-serif;
    --default-font-size: .9375rem;
    --default-font-weight: 400;
    --default-font-padding: 0.375rem 0.75rem;
    //--default-font-padding: 0.5rem 0.75rem 0.25rem; // For CircularStd

    // Miscellaneous
    --default-border: 1px solid var(--colour-border);
    --default-border-radius: .25rem;

    /************************/

    * {
        //display: block; // Does disabling this break something else? I disabled this because it would make all tags create a newline.
        font-size: var(--default-font-size);
        font-weight: var(--default-font-weight);
        font-family: var(--default-font-family);
    }

    main {
        background-color: #f9fbfd;
        padding: 0 2rem 6rem;
        position: relative;
        overflow: auto;
        overflow-y: scroll;

        .main__header {
            display: grid;
            grid-template-columns: minmax(min-content, 1fr) min-content;
            overflow: hidden;

            > div {
                display: grid;
                align-content: center;
                white-space: nowrap;
            }
        }
    }

    section {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 0;
    }

    nav {
        z-index: 1000;
        padding: 0.75rem 1.5rem;

        background-color: #fff;

        &.nav__sidebar {
            overflow-y: scroll;
            border-right: 1px solid #e3ebf6;
        }

        &.nav__topbar {
            display: grid;
            grid-auto-flow: column;
            place-content: start;
            grid-gap: 1rem;

            position: fixed;
            top: 0;
            left: 250px;
            width: calc(100% - 250px);

            border: solid #e3ebf6;
            border-width: 0 0 1px 0;
        }

        .nav__links {
            display: grid;
            grid-auto-flow: row;
            grid-gap: 1rem;

            transition: all .1s ease,
                        padding .25s ease;
        }

        .nav__link, .nav__dropdown {
            display: grid;
            grid-template-columns: 20px 1fr;
            grid-gap: .5rem;

            color: #6e84a3;
            font-size: 1rem;
            font-weight: 300;
            text-decoration: none;
            cursor: pointer;
            user-select: none;

            transition: all .1s ease,
                        padding .25s ease;

            span {
                display: grid;
                place-content: center;
                width: min-content;
                white-space: nowrap;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 2px;

                    path {
                        transition: all .1s ease;
                        fill: #6e84a3;
                    }
                }
            }

            &:hover {
                color: black;

                svg {
                    path {
                        fill: black;
                    }
                }
            }
        }

        .nav__link {
            position: relative;
            z-index: 0;

            &[aria-current="page"] {
                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    height: calc(var(--default-font-size) * 1.5 + .25rem);
                    width: calc(100% + 1rem);
                    bottom: 0;
                    left: -.5rem;

                    background-color: #edf2f9;
                    border-radius: .375rem;
                }
            }

            &[data-implemented="false"] {
                span:last-child {
                    text-decoration: line-through;
                }
            }

            span:last-child {
                display: grid;
                grid-auto-flow: column;
                grid-gap: 8px;

                svg {
                    width: 13px;
                    height: 100%;
                }
            }
        }

        .nav__dropdown {
            grid-template-columns: min-content 1fr min-content;

            > span:last-child {
                transform: rotateZ(180deg);
                transition: all .15s ease;
            }

            &.open {
                > *:last-child {
                    transform: rotateZ(0);
                }
            }

            &-content {
                .nav__link {
                    span {
                        font-size: .8125rem;
                    }


                    &[aria-current="page"] {
                        &:before {
                            height: calc(.8125rem * 1.5 + .25rem);
                            bottom: calc(-.125rem + 1px);
                            left: 0;
                            width: 100%;
                        }
                    }

                    &:first-child {
                        padding-top: .5rem;
                    }
                }
            }
        }

        .nav__logo {
            cursor: pointer;

            img {
                max-height: 56px;
                transition: max-height .25s ease;
                transform: translateY(-8px);

                @media only screen and (max-width: 600px) {
                    max-height: 38px;
                    transform: translateY(-4px);
                }
            }
        }
    }

    .group {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

        &--vertical {
            grid-template-columns: 1fr;
        }
    }

    hr {
        border: solid #e3ebf6;
        border-width: 0 0 1px 0;
    }
}
