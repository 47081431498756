@import "breakpoints";

.animation {
    &-slide-in {
        animation-duration: .5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: slide-in;

        @keyframes slide-in {
            0% {
                opacity: 0;
                transform: translateX(25px);
            }
            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    &-slide-out {
        animation-duration: .5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: slide-out;

        @keyframes slide-out {
            0% {
                opacity: 1;
                transform: translateX(0);
            }
            100% {
                pointer-events: none;
                opacity: 0;
                transform: translateX(-25px);
            }
        }
    }

    &-fade-in {
        animation-duration: .5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: fade-in;

        @keyframes fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }

    &-fade-out {
        animation-duration: .5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: fade-out;

        @keyframes fade-out {
            0% {
                opacity: 1;
            }
            100% {
                pointer-events: none;
                opacity: 0;
            }
        }
    }
}
