@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

#__veis, .__veis {
    padding: 0;
    overflow: unset;

    --colour-border: #D7DFE9;

    h1, h2, h3, h4, h5, h6, p, button, div, a {
        transition: opacity .5s ease,
        color .25s ease;
        letter-spacing: .5px;
    }

    @mixin input-addon() {
        display: table-cell;
        vertical-align: middle;
        background-color: #f5f5f5;
        border: 1px solid var(--colour-border);
        white-space: nowrap;
        width: 1%;

        color: #555555;
        font-size: 16px;
        line-height: 1.33;
        //font-family: "FuturaPtBook", Arial, sans-serif;

        padding: 0 8px;

        margin: 0.25rem 0 1rem 0;

        img {
            user-select: none;
            margin-top: 6px;
            width: 24px;
            max-height: 24px;
        }

        @media only screen and (max-width: 600px) {
            font-size: 14px;
            padding: 0 6px;
        }
    }

    section {
        &.section__main {
            padding-top: 100px;

            @media only screen and (max-width: 600px) {
                padding-top: 64px;
            }
        }

        @media (min-width: 981px) {
            padding: 4% 0;
        }

        @media only screen and (min-width: 1350px) {
            padding: 54px 0;
        }

        &.section__header {
            display: grid;
            grid-auto-flow: row;

            width: 100vw;
            height: 100vh;

            background-color: #2e7044;

            background-blend-mode: multiply;
            background-image: url(https://veis.no/wp-content/uploads/2023/01/Roundabout_Green_Digital.png);
            background-size: 175%;
            background-position: center;
            background-repeat: no-repeat;
            overflow: unset;

            @media only screen and (max-width: 600px) {
                padding: 2rem 0;
            }

            @media only screen and (min-height: 600px) and (min-width: 600px) {
                background-size: 100%;
                height: 580px;
            }

            //@media only screen and (min-height: 1200px) {
            //    padding-top: 185px;
            //    padding-bottom: 75px;
            //}

            &--short {
                max-height: 320px;

                @media only screen and (max-width: 600px) {
                    max-height: 260px;
                    background-size: 140% !important;
                }
            }

            .call-to-action {
                padding: 4rem 0;
                display: grid;
                place-content: center;

                > h1 {
                    max-width: 650px;
                }
            }
        }
    }

    .input-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        // Emulates flex gap
        margin-right: -12px;

        > div, button {
            // Emulates flex gap
            margin-right: 12px;

            flex: 1 0 200px;
            min-width: 200px;
        }

        &.always {
            > div, button {
                flex: 1 0 140px;
                min-width: 140px;
            }
        }
    }

    .consultant-card {
        background-color: #daf3fe;
        border-radius: 12px;
        padding: 30px 40px;

        @media only screen and (max-width: 600px) {
            padding: 12px;
        }
    }

    .row {
        all: unset;

        width: 80%;
        max-width: 1080px;
        margin: auto;
        position: relative;

        @media only screen and (max-width: 600px) {
            width: 90%;
        }
    }

    header {
        z-index: 99;
        width: 100vw;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: transparent;

        position: absolute;
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;


        .header__row {
            margin: auto;
            width: 90%;
            max-width: 1200px;

            a {
                display: grid;
                place-content: center;
                width: min-content;
            }
        }

        .wp-image-19 {
            max-height: 40px;
            width: auto;
        }
    }

    footer {
        min-height: 100px;
    }

    h1 {
        //all: unset;
        margin: 0;

        font-family: 'Inter',Helvetica,Arial,Lucida,sans-serif;
        color: #333;
        font-weight: 500;
        font-size: 40px;
        line-height: 120%;
        max-width: 95vw;

        strong {
            font-weight: 700;
        }
    }

    #__veis-form {
        display: grid;
        place-content: center;
    }

    #__veis-section {
        display: grid;
        place-content: center;

        > h1 {
            max-width: 650px;
        }
    }

    .phone-container {
        position: relative;
        //z-index: 2;

        .country-list {
            z-index: 3;
            position: absolute;
            background-color: white;
            min-width: 220px;
            width: 100%;
            margin-top: -1px;
            margin-left: -9px;

            border-radius: 0 0 5px 5px;
            border: 1px solid var(--colour-border);
            transition: all .25s ease;

            max-height: 0;
            opacity: 0;
            overflow: hidden;

            &-item-highlighted {
                background-color: #f5f5f5;

                label {
                    font-weight: 500;
                }
            }

            &.open {
                overflow-y: scroll;
                max-height: 200px;
                opacity: 1;
            }

            > div {
                padding: 0 8px;
            }

            @media only screen and (max-width: 600px) {
                margin-left: -7px;

                > div {
                    padding: 0 6px;
                }
            }

            div {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: left;

                &.selected, &:hover {
                    background-color: #f1f1f1;
                }

                label {
                    font-size: 16px;
                    cursor: pointer;
                    margin-left: 8px;
                    line-height: 36px;
                }
            }
        }
    }

    .prefix-addon {
        @include input-addon();

        border-right: 0;
        border-radius: 5px 0 0 5px;
        -webkit-border-radius: 5px 0 0 5px;
    }

    .label__tooltip {
        display: flex;
        flex-direction: row;
    }

    .flag-addon {
        cursor: pointer;

        span {
            user-select: none;
            font-size: 12px;
            vertical-align: top;

            img {
                height: 24px;
                width: 16px;
                padding-left: 4px;
            }
        }

        .hidden-input {
            position: absolute;
            opacity: 0;
            width: 50%;
            height: 100%;
        }

        &.disabled {
            pointer-events: none;
        }

        &.only-one {
            span {
                display: none;
            }
        }
    }

    .icon {
        all: unset;

        .group {
            all: unset;

            display: grid;
            grid-template-columns: 30px 1fr;
            grid-gap: 8px;

            span {
                line-height: 30px;
            }
        }

        svg {
            width: 30px;
            height: 30px;

            path {
                fill: #a9a9a6;
            }
        }
    }

    .veis-cta__buttons {
        display: grid;
        grid-gap: 1rem;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        place-content: center;

        //display: grid;
        ////grid-template-columns: auto;
        //grid-template-columns: repeat(2, minmax(200px, 1fr));
        //place-content: center;
        //grid-gap: 1rem;
        ////grid-auto-flow: column;
        //width: min-content;
        //margin: auto;

        a {
            min-width: 200px;
            width: min-content;
            text-align: center;
            margin: auto;

            color: #017745;
            border-color: #FFFFFF;
            border-radius: 8px;
            font-size: 16px;
            font-family: 'Inter',Helvetica,Arial,Lucida,sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            background-color: #ffffff;

            line-height: 1.7rem;
            padding: 12px 45px;
            transition: all .2s ease;

            &:hover {
                color: #5B8500;
                background-image: initial;
                background-color: #daf4fe;
            }
        }
    }

    .veis-button__row {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 1rem;
        margin-top: 1rem;
    }

    button {
        z-index: 0;
        position: relative;
        background-color: #006b06;
        border: 0 none #fff;
        border-radius: 5px;
        padding: 11px;

        cursor: pointer;
        transition: background-color .33s ease;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            transition: background-color .33s ease;
            background-color: #006b06;
        }

        &:hover:not(&:disabled)::after {
            background-color: #599c70;
        }

        &:disabled {
            pointer-events: none;

            &::after {
                background-color: transparent;
            }
        }

        &.flex {
            max-width: 100%;

            &--large {
                width: 300px;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
            }
        }

        div {
            z-index: 1;
            position: relative;
            color: rgb(255, 255, 255);
            font-size: 16px;
            font-weight: 500;
            font-family: 'Inter',Helvetica,Arial,Lucida,sans-serif!important;
            line-height: 1.8;
        }

        background-image: linear-gradient(
                45deg,
                #006b06 25%,
                transparent 25%,
                transparent 50%,
                #006b06 50%,
                #006b06 75%,
                transparent 75%,
                transparent
        );

        background-size: 2rem 2rem;
        animation: 1s linear infinite loading-stripes;

        @keyframes loading-stripes {
            0% {
                background-position: 2rem 0;
            }
            to {
                background-position: 0 0;
            }
        }

        &[data-processing="true"] {
            background-blend-mode: overlay;
            background-color: #006b06;
        }
    }

    form {
        max-width: min(550px, 95vw);
        width: 550px;
        padding: 10px 20px;

        background-color: #FFFFFF;
        border: 1px dashed #006B06FF;
        border-radius: 5px;

        label {
            color: #000;
            line-height: 1.8;
            font-size: 1rem;
            font-family: Arial, serif;
        }

        .input-container {
            position: relative;
            display: table;
            border-collapse: separate;
            width: 100%;
            height: 54px;
            margin: 0.33rem 0 16px;

            input {
                margin: 0 !important;
            }

            .prefix-addon {
                background-color: #f9f9f9;
                border: none;
            }
        }

        input {
            z-index: 1;
            position: relative;
            display: table-cell;
            float: left;

            box-sizing: border-box;
            background-color: #F9F9F9;
            border: 0 none #FFFFFF;
            border-radius: 5px;
            padding: 15px 20px 15px 20px;
            box-shadow: 0 0 0 0 #FFFFFF;
            font-family: Arial, serif;
            font-size: 16px;
            font-weight: 400;
            background-clip: inherit;
            width: 100%;
            color: #000;
            height: 54px;

            margin: 0.33rem 0 16px;

            &::placeholder {
                color: #AFB8BC;
            }

            &.prefix {
                border-radius: 0 5px 5px 0;
                -webkit-border-radius: 0 5px 5px 0;
            }
        }
    }

    .mt-3 {
        margin-top: 1rem;
    }

    .mt-4 {
        margin-top: 2rem;
    }

    .mb-3 {
        margin-bottom: 1rem;
    }

    .mb-4 {
        margin-bottom: 2rem;
    }
}
