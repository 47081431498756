#__legacy {
    @import "../shared/layout";
    @import "breakpoints";
    @import "backgrounds";
    @import "typography";
    @import "navigation";
    @import "form";
    @import "grid";
    @import "helpers";
    @import "miscellaneous";
    @import "buttons";
    @import "funnel";
    @import "animations";
    @import "loading-indicators";
    @import "card";
    @import "border";
    @import "inputs";
    @import "image";
    @import "icons";
    @import "envelopes";
    @import "tables";
    @import "svgs";
    @import "toggle";
    @import "info";
    @import "botpress";

    /************************/
    /****** Variables ******/
    // Fonts
    --default-fonts: "CircularStd", sans-serif;

    // Sizes
    --size-icon-default: 24px;

    // Colours
    --colour-dark: #282828;
    --colour-dark-grey: #444444;
    --colour-grey: #555555;
    --colour-gray: #555555;
    --colour-light: #acacac;

    --colour-light-grey: #fafafb;
    --colour-blue: #b2d0e2;
    --colour-yellow: #f5d9a1;
    --colour-green: #a0d5c8;
    --colour-dark-green: #44a1a7;
    --colour-darker-green: #25675f;
    --colour-pink: #f1bfcb;
    --colour-orange: #f6c7ac;
    --colour-purple: #d3c5e8;
    --colour-lime: #c1deb8;
    --colour-light-blue: #c4f0f6;
    --colour-red: #f6a6a6;

    --colour-icon-primary: #FFFFFF;

    --colour-link-light: #5296ff;
    --colour-link: #007bff;
    --colour-link-highlight: #004895;

    --colour-primary: #77A6C3;
    --colour-secondary: #244a66;
    --colour-gold: #e7c674;

    --color-primary-background: #deedf6;
    --color-primary-background-light: #eaf4fa;
    --colour-yellow-background: #fff8e6;

    --colour-border: #D7DFE9;
    --colour-border-darkened: #c3d8e7;
    --colour-border-yellow: #ffecb4;

    --colour-hover: #e3e9f0;
    --colour-hover-darkened: #d0dfea;
    /************************/

    font-family: var(--default-fonts);
    color: var(--colour-grey);

    main {
        padding: 4rem 0 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        overflow: hidden; // TODO: Could this cause problems?
    }

    section {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 4rem 0;

        @media only screen and (max-width: $Tablet) {
            padding: 3rem 0;

            &:first-child {
                padding-top: 2rem;
            }
        }

        &.overflow-hide {
            overflow: hidden;
        }

        &.large {
            padding: 6rem 0;

            @media only screen and (max-width: $Tablet) {
                padding: 5rem 0;

                &:first-child {
                    padding-top: 3rem;
                }
            }
        }

        &.cover {
            flex: none;
            min-height: 100vh;

            &:first-child {
                min-height: calc(100vh - 8rem);

                &.section-center {
                    min-height: calc(100vh - 4rem);
                }

                @media only screen and (max-width: $Tablet) {
                    min-height: calc(100vh - 6rem);

                    &.section-center {
                        min-height: calc(100vh - 4rem);
                    }
                }
            }

            &:first-child:last-child {
                min-height: calc(100vh - 8rem);

                &.section-center {
                    min-height: calc(100vh - 4rem);
                }

                @media only screen and (max-width: $Tablet) {
                    min-height: calc(100vh - 6rem);

                    &.section-center {
                        min-height: calc(100vh - 4rem);
                    }
                }
            }
        }

        &.section-center {
            justify-content: center;
        }
    }

    .group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
    }
}
