.card {
    position: relative;
    margin-bottom: 1.5rem;

    background-color: white;
    border: 1px solid #edf2f9;
    border-radius: 0.5rem;
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);

    &[data-missing="true"][data-loading="false"] {
        display: none;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        --colour-pulsing-end: white;
        --colour-pulsing-start: #ececec;

        opacity: 0;
        transition: opacity .2s ease;
        pointer-events: none;
        border-radius: 0.5rem;
        background-color: var(--colour-pulsing-start);
    }

    &[data-loading="true"] {
        &::after {
            opacity: 1;
            pointer-events: auto;
            animation: .5s ease alternate infinite pulsing-background;
        }
    }
}

.card__header, .card__body {
    padding: 1rem 1.5rem;
}

.card__header {
    height: 60px;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #edf2f9;

    > div {
        height: 100%;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        justify-content: space-between;

        font-weight: 500;
    }
}

.card__body {

}

.card__body--upload {
    display: grid;
    place-content: center;
    position: relative;
    background-color: #eaf4fa;

    span {
        font-size: .8rem;
        font-style: italic;
        z-index: 1;
        pointer-events: none;
    }

    &-dragging {
        background-color: #fff8e6;
    }
}

.card__body--disabled {
    background-color: var(--colour-disabled);
}

.card__body--uploading {
    --button-background-loading-colour: #dfe2e5;

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        transition: all .25s ease;

        background-image: linear-gradient(
                45deg,
                var(--button-background-loading-colour) 25%,
                transparent 25%,
                transparent 50%,
                var(--button-background-loading-colour) 50%,
                var(--button-background-loading-colour) 75%,
                transparent 75%,
                transparent
        );

        background-size: 2rem 2rem;
        animation: 1s linear infinite loading-stripes;

        @keyframes loading-stripes {
            0% {
                background-position: 2rem 0;
            }
            to {
                background-position: 0 0;
            }
        }
    }
}

.card__details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: .5rem;
}

.card__detail {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: min-content 1fr min-content;

    > div:first-child {
        white-space: nowrap;
        font-weight: 600;
    }

    > div:nth-child(2) {
        border-top: 1px dotted #d6d6d6;
        margin-top: 12px;
    }

    > div:last-child {
        white-space: nowrap;
        color: rgba(18,38,63,.5);
    }
}

.card__table {
    color: #12263f;
    overflow-x: auto;

    .table__buttons {
        display: grid;
        grid-auto-flow: column;
        place-content: start;
        --table-button-colour: #12263f;
        height: 15px;

        svg {
            width: 15px;
            height: 15px;
            cursor: pointer;

            path {
                transition: all .1s ease;
                fill: var(--table-button-colour);
            }
        }
    }

    .table__button {
        &--delete {
            --table-button-colour: var(--colour-danger)
        }

        &--archive {
            --table-button-colour: var(--colour-info)
        }
    }

    .table__header, .table__item {
        display: grid;
        grid-template-columns: 250px 1fr 1fr;

        > div:first-child {
            padding-left: 1.5rem;

            &:has(input) {
                padding-left: 1rem;
            }
        }

        > div:last-child {
            padding-right: 1.5rem;

            &:has(input) {
                padding-right: 1rem;
            }
        }

        > div {
            padding: 1rem;

            &:has(input) {
                padding: .5rem;
            }
        }
    }

    .table__header {
        > div {
            background-color: #f9fbfd;
            text-transform: uppercase;
            font-size: .625rem;
            font-weight: 600;
            letter-spacing: .08em;
            color: #95aac9;
            border-bottom-width: 1px;

            span, small {
                font-style: italic;
                font-size: .5rem;
            }

            &:hover {
                svg {
                    path {
                        fill: #1657af;
                    }
                }
            }
        }

        span {
            position: relative;
            margin-left: .5rem;

            &.rotated {
                svg {
                    transform: rotateZ(180deg);
                }
            }
        }

        svg {
            position: absolute;
            width: 16px;
            height: 16px;
            cursor: pointer;
            transform: translateY(-1px);
            transition: all .1s ease;

            path {
                transition: all .1s ease;
                fill: var(--colour-muted);
            }
        }
    }

    .table__item {
        > div {
            border-top: 1px solid #edf2f9;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        > div:has(input) {
            display: grid;
            grid-auto-flow: column;
            align-items: center;

            span {
                display: grid;
                align-content: center;
            }
        }

        div, div * {
            font-size: .8125rem;
        }

        input {
            position: relative;
            padding: .5rem;
            border: 0;
            width: 100%;
        }

        small {
            font-style: italic;
            font-size: .66rem;
            font-weight: 500;
            color: var(--colour-primary);
        }

        input:disabled {
            background-color: transparent;
        }

        input:not(:disabled):hover {
            background-color: #edf2f9;
        }

        &--button {
            &-info {
                svg { path { fill: #39afd1; } }
            }
        }

        &--badges div {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            overflow: hidden;
            width: min-content;
            max-width: 100%;
            height: 100%;
        }

        &--misc {
            display: grid;
            align-items: center;
            place-content: end;

            svg {
                width: 16px;
                height: 16px;
                cursor: pointer;

                path {
                    transition: all .1s ease;
                    fill: #d2ddec;
                }

                &:hover {
                    path {
                        fill: #1657af;
                    }
                }
            }

            .dropdown {
                position: absolute;
                //transform: translate(calc(-100% - .5rem), 2rem);
                right: 2.5rem;
                transform: translateY(2rem);
                z-index: 1000;
                display: none;
                min-width: 10rem;
                padding: 0.5rem 0;
                margin: 0 0 0;
                font-size: .9375rem;
                color: #12263f;
                text-align: left;
                list-style: none;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid rgba(18,38,63,.1);
                border-radius: 0.375rem;

                &--item {
                    display: block;
                    width: 100%;
                    padding: 0.375rem 1.5rem;
                    clear: both;
                    font-weight: 400;
                    color: #6e84a3;
                    text-align: inherit;
                    white-space: nowrap;
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;

                    &:hover {
                        color: #12263f;
                        text-decoration: none;
                        background-color: transparent;
                    }
                }

                &.show {
                    display: block;
                }
            }
        }
    }
}
