svg path {
    transition: all .2s ease;

    &.svg__fill--primary {
        fill: var(--colour-icon-primary);
    }
}

svg.svg__colour {
    &--grey path {
        fill: #565656;
    }

    &--primary path {
        fill: #264c68;
    }
}

.svg__container {
    display: grid;
    vertical-align: center;

    margin-left: 6px;
}

.svg__button {
    cursor: pointer;

    &:hover {
        transform: rotate(180deg);
    }
}

.svg__info {
    cursor: help;
    position: relative;

    &:hover div {
        top: calc(100% - 3px);
        opacity: 1;
    }
}

.svg--spin-slow {
    animation-delay: .25s;
    animation-duration: 1s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: anim-spin;

    @keyframes anim-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
}

.svg--small {
    width: 24px;
    height: 24px;
}

.svg--medium {
    width: 32px;
    height: 32px;
}
