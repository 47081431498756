.bg-dark-blue {
    background-color: var(--colour-primary);
    * {
        --default-font-colour: white;
    }
}

.bg-light-green {
    background-color: var(--colour-light-green);
}

.bg-light-yellow {
    background-color: var(--colour-light-yellow);
}

.br-light-yellow {
    border-color: var(--colour-light-yellow);
}

.bg-light-grey, .bg-light-gray {
    background-color: var(--colour-light-grey);
}

.bg-pulsing {
    overflow: hidden;
    @keyframes pulsing {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    position: relative;
    background-image: linear-gradient(
            to right,
            hsl(220, 68%, 94%),
            hsl(0, 0%, 83%)
    );
    z-index: 1;

    &::before {
        animation: pulsing 1s infinite;
        animation-direction: alternate;
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(
                to bottom,
                hsl(0, 7%, 91%),
                hsl(0, 0%, 72%)
        );
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
    }
}
