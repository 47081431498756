@import "breakpoints";

.background-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.default-background {
    position: absolute;
    user-select: none;
    pointer-events: none;
    overflow: hidden;

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    div {
        position: relative;
        background-image: url("../../assets/background-swirl-01.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        width: 90.6vw;
        height: 126vh;

        top: -43.68%;
        left: 32.5%;

        @media only screen and (max-width: $LaptopLarge) {
            width: 1400px;
            height: 1200px;

            top: -350px;
            left: 200px;
        }

        @media only screen and (max-width: $Laptop) {
            left: 20%;
            top: -15%;
        }

        @media only screen and (max-width: $TabletSmall) {
            width: 120vw;
            height: 145vw;

            left: 25vw;
            top: -20vw;
        }
    }
}
