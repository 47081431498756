.checkbox {
    display: grid;
    position: relative;
    transition: all .25s ease;

    label {
        cursor: pointer;
    }

    * {
        user-select: none;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        opacity: 0;
        cursor: pointer;

        &:disabled {
            cursor: auto;
        }
    }

    > div:first-child {

    }

    > div:last-child {
        display: none;
    }

    &--width-480 {
        width: 100%;
        max-width: 480px;
    }
}

.checkbox > div:nth-child(2) {
    position: relative;
    width: min-content;
}

.checkbox--horizontal {
    grid-gap: 1rem;

    label {
        display: grid;
        margin: 0;
        min-height: 100%;
        align-content: center;
        user-select: auto;
    }

    &-small {
        --toggle-height: 1.5rem !important;
        --toggle-width: 3rem !important;

        label {
            font-size: .8rem;
        }
    }
}

.checkbox:not(.checkbox--pre) {
    &.checkbox--horizontal {
        grid-template-columns: min-content 1fr;

        > div:first-child {
            display: none;
        }

        > div:last-child {
            display: block;
        }
    }
}

.checkbox--horizontal.checkbox--pre {
    grid-template-columns: 1fr min-content;
}

.checkbox__style--default {
    --checkbox-size: 2rem;

    > div:nth-child(2) {
        width: var(--checkbox-size);
        min-height: var(--checkbox-size);

        &::before, &::after {
            position: absolute;
            display: block;
            top: calc(50% - var(--checkbox-size)/2);
            width: var(--checkbox-size);
            height: var(--checkbox-size);

            content: "";
            border-radius: 0.375rem;
            pointer-events: none;
            transition: all .2s ease;
        }

        &::before {
            background-color: #e3ebf6;
            border: #b1c2d9 solid 0;
        }

        &::after {
            background: no-repeat center;
            background-size: 50%;
        }

        input {
            min-height: 0;
            top: calc(50% - var(--checkbox-size)/2);
            height: var(--checkbox-size);
        }
    }

    &[data-enabled="true"] > div:nth-child(2) {
        &::before {
            color: #fff;
            border-color: var(--colour-primary);
            background-color: var(--colour-primary);
        }

        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        }
    }

    &[data-disabled="true"] > div:nth-child(2) {
        &::before {
            border-color: var(--colour-disabled);
            background-color: var(--colour-disabled);
        }

        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%236c7177' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        }
    }
}

.checkbox__style--literal {
    > div:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(2, minmax(max-content, 1fr));

        background-color: #fff;
        width: 125px;

        border-radius: .25rem;
        overflow: hidden;
        border: 1px solid var(--colour-border);

        div {
            display: grid;
            place-content: center;

            padding: var(--default-font-padding);
            white-space: nowrap;

            &:first-child {
                border-right: 1px solid var(--colour-border);
            }
        }

        &:before {
            position: absolute;
            content: '';
            background-color: var(--colour-disabled);
            height: 100%;
            width: 50%;
            left: 0;

            transition: all .25s ease;
        }
    }

    &[data-enabled="true"] > div:nth-child(2) {
        &:before {
            left: 50%;
            background-color: #2c7be5;
        }
    }

    &[data-disabled="true"] > div:nth-child(2) {
        background-color: var(--colour-disabled);
        color: var(--colour-disabled-font);

        &:before {
            background-color: #909598;
        }
    }
}

.checkbox__style--toggle {
    --toggle-height: 2rem;
    --toggle-width: 4rem;
    --toggle-margin: calc(var(--toggle-height) * 0.125);
    --toggle-ball-height: calc(var(--toggle-height) - var(--toggle-margin) * 2);

    > div:nth-child(2) {
        display: grid;
        align-content: center;
        width: var(--toggle-width);
        height: var(--toggle-height);

        &::before, &::after {
            position: absolute;

            content: "";
            border-radius: var(--toggle-height);
            pointer-events: none;
            transition: all .2s ease;
        }

        &::before {
            height: var(--toggle-height);
            width: var(--toggle-width);

            background-color: #e3ebf6;
        }

        &::after {
            margin: var(--toggle-margin);
            width: var(--toggle-ball-height);
            height: var(--toggle-ball-height);

            background-color: white
        }
    }

    &[data-enabled="true"] > div:nth-child(2) {
        &::before {
            background-color: var(--colour-primary);
        }

        &::after {
            transform: translateX(calc((var(--toggle-width) / 2) - ((var(--toggle-height)) - (var(--toggle-width) / 2))));
        }
    }

    &[data-disabled="true"] {
        > div:nth-child(2) {
            &::before {
                background-color: #909598;
            }
        }


        &[data-enabled="true"] > div:nth-child(2) {
            &::before {
                background-color: var(--colour-disabled-font);
            }
        }
    }
}
