@keyframes pulsing-background-animation {
    0% {
        background: linear-gradient(45deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 90%,
            rgba(0, 0, 0, 0) 100%
        );
    }
    100% {
        background: linear-gradient(45deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 10%,
            rgba(0, 0, 0, 0) 100%
        );
    }
}
