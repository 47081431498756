.info__container {
    position: absolute;
    padding: 0 12px;
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 400px;
    width: max-content;
    pointer-events: none;

    border-radius: 6px;
    background-color: var(--colour-secondary);
    transition: all .2s ease;
    opacity: 0;

    p {
        color: white;
        font-size: 14px;
    }
}
