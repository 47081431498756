button {
    max-width: 100%;
    background: none;
    border: none;
    outline: inherit;
    padding: 0;
    margin: 0;
}

button.btn {
    display: inline-block;

    &--block {
        display: block;
        width: 100%;
    }
}

.button {
    display: inline-grid;
    grid-auto-flow: column;
}

button.btn, .button {
    position: relative;
    padding: 0.5rem 0.75rem;
    z-index: 0;

    font-weight: 400;
    font-size: .9375rem;
    line-height: 1.5;
    letter-spacing: 0.05rem;
    white-space: nowrap;

    cursor: pointer;
    user-select: none;
    transition: all .25s ease;
    outline: none;

    --button-font-colour: white;
    --button-background-colour: black;
    --button-background-loading-colour: black;
    --button-border-colour: black;
    --button-image-colour: white;

    &--primary {
        --button-background-loading-colour: #1a68d1;
        --button-background-colour: #2c7be5;
        --button-border-colour: #2c7be5;

        &:hover {
            --button-background-colour: #1a68d1;
            --button-border-colour: #1a68d1;
        }

        &[data-loading="true"] {
            --button-background-colour: #2c7be5;
            --button-border-colour: #2c7be5;
        }
    }

    &--warning {
        --button-font-colour: black;
        --button-background-loading-colour: #e7b63b;
        --button-background-colour: var(--colour-warning);
        --button-border-colour: var(--colour-warning);

        &:hover {
            --button-background-colour: #e7b63b;
            --button-border-colour: #e7b63b;
        }
    }

    &--secondary {
        --button-font-colour: var(--default-font-colour);
        --button-background-loading-colour: #e0e4ea;
        --button-background-colour: #EDF2F9;
        --button-border-colour: #EDF2F9;

        &:hover {
            --button-background-colour: #e0e4ea;
            --button-border-colour: #e0e4ea;
        }

        &[data-loading="true"] {
            --button-background-colour: #EDF2F9;
            --button-border-colour: #EDF2F9;
        }
    }

    &[data-loading="true"] {
        cursor: default;
        pointer-events: none;

        &:before {
            opacity: 1;
        }
    }

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all .25s ease;

        background-image: linear-gradient(
                45deg,
                var(--button-background-loading-colour) 25%,
                transparent 25%,
                transparent 50%,
                var(--button-background-loading-colour) 50%,
                var(--button-background-loading-colour) 75%,
                transparent 75%,
                transparent
        );

        background-size: 2rem 2rem;
        animation: 1s linear infinite loading-stripes;

        @keyframes loading-stripes {
            0% {
                background-position: 2rem 0;
            }
            to {
                background-position: 0 0;
            }
        }
    }

    &--disabled {
        pointer-events: none;
    }

    &:disabled, &--disabled {
        cursor: auto;

        --button-background-loading-colour: var(--colour-disabled-alt);
        --button-background-colour: var(--colour-disabled);
        --button-border-colour: var(--colour-disabled);
        --button-font-colour: var(--colour-disabled-font);
    }

    &--disabled {
        &:not(.button--selected) {
            opacity: 0.2;
        }
    }

    color: var(--button-font-colour);
    background-color: var(--button-background-colour);
    border: 1px solid var(--button-border-colour);
    border-radius: 0.375rem;
}

button svg {
    width: 18px;
    //height: 18px;
    height: calc(100% - 1px);

    path {
        transition: all .1s ease;
        fill: var(--button-image-colour);
    }
}

.button--medium {
    padding: var(--default-font-padding);
    border-radius: 0.25rem;
    line-height: 1.5;
}

.button--small {
    padding: 0.125rem 0.5rem;
    font-size: .8125rem;
    line-height: 1.75;
    border-radius: 0.25rem;
}

.button--minimum {
    width: min-content;
}
