#__default {
    @import "../legacy/loading-indicators";
    @import "../shared/alerts";
    @import "../shared/layout";
    @import "../shared/collapsable";
    @import "../shared/checkbox";
    @import "../shared/matrix";
    @import "../shared/draft";
    @import "../shared/cards";
    @import "../shared/documents";
    @import "../shared/lists";
    @import "../shared/tooltip";
    @import "./keyframes";
    @import "./collapsable";
    @import "./typography";
    @import "./buttons";
    @import "./inputs";
    @import "./forms";
    @import "./backgrounds";
    @import "./miscellaneous";
    @import "./react-pdf";

    /************************/
    /****** Variables ******/
    // Colours
    --colour-muted: #95aac9;
    //--colour-border: #f5f5f5;
    --colour-border: #dddeed;
    --colour-disabled: #dddeed;
    --colour-disabled-font: #6c7177;
    //--colour-primary: #dddeed;
    --colour-primary: #224560;
    --colour-secondary: #2c7be5;
    --colour-white: #fff;
    --colour-black: #000;

    --colour-light-green: #eff8f6;
    --colour-light-grey: #f9f9f9;
    --colour-light-yellow: #f9f1dc;

    --colour-success: #00D97E;
    --colour-success-alt: #01be6f;
    --colour-danger: #E63757;
    --colour-warning: #F6C343;
    --colour-info: #39AFD1;
    --colour-tooltip: var(--colour-secondary);
    --colour-emphasis: #0571ad;

    --background-colour-code: #2a2d30;
    --default-code-colour: #f9f9f9;

    // Fonts
    --default-font-colour: #000;
    --default-font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
    --default-font-size: 1.125rem;
    --default-font-weight: 300;
    --default-font-padding: 0.375rem 0.75rem;

    // Miscellaneous
    --default-border: 2px solid var(--colour-border);
    --default-border-radius: .25rem;
    /************************/

    //@media only screen and (max-width: 800px) {
    //    --default-font-size: 1.0625rem;
    //}

    main {
        position: relative;
        padding: 0 0 6rem;

        // Inspired by https://ryanmulligan.dev/blog/layout-breakouts/
        --gap: max(1rem, 2.5%);
        --full: minmax(var(--gap), 1fr);
        //--content: min(1080px, 100% - var(--gap) * 2);
        --content: minmax(0, 10rem);
        --small: minmax(0, 8.75rem);
        --short: min(480px, 100% - var(--gap) * 2);
        --feature: minmax(0, 1fr);
        --semifull: minmax(0, 5%);

        display: grid;
        grid-template-columns:
            [full-start] var(--full)
            [semifull-start] var(--semifull)
            [feature-start] var(--feature)
            [content-start] var(--content)
            [small-start] var(--small)
            [short-start] var(--short) [short-end]
            var(--small) [small-end]
            var(--content) [content-end]
            var(--feature) [feature-end]
            var(--semifull) [semifull-end]
            var(--full) [full-end];

        > * {
            grid-column: content;
        }

        .content-small {
            grid-column: small;
        }

        .content-short {
            grid-column: short;
        }

        .feature {
            grid-column: feature;
        }

        .semi-full {
            grid-column: semifull;
        }

        .full {
            grid-column: full;
        }
    }

    section {
        container-type: inline-size; // Could be problematic
        position: relative;
        padding: 1.5rem 0;
    }

    .group {
        &--flex {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;
        }
    }

    .unset {
        //all: unset;
    }

    .space-between {
        justify-content: space-between;
    }

    nav {
        height: 150px;
        max-width: 1300px;
        margin: 0 auto;
        position: relative;
        display: grid;
        grid-template-columns: min-content 1fr;
        transition: all .25s ease,
                    height 0s ease;

        .nav__logo {
            display: grid;
            place-content: center;
            width: calc(1300px * 0.21);

            img {
                cursor: pointer;
                max-width: calc(141.5px + 2rem);
                padding: 1rem;
            }
        }

        @media only screen and (max-width: 600px) {
            height: 110px;

            .nav__logo {
                width: calc(141.5px + 1rem);

                img {
                    max-width: calc(141.5px + 1rem);
                }
            }
        }

        &.hide {
            transition: all 0s ease;
            height: min(max(1rem, 2.5vw), 50px);

            * {
                display: none;
            }
        }
    }

    div {
        transition: all .2s ease,
                    border 0s ease;
    }

    footer {
        padding: 5rem 0 1rem;
    }

    .youtube {
        max-width: 100%;
        max-height: 360px;
        height: auto;
        aspect-ratio: 720 / 360;
        border: none;
    }

    .temp__row {
        display: grid;
        grid-template-columns: repeat(auto-fit, 320px);
        //grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        grid-auto-flow: column;
        place-content: center;

        .temp__nav {
            margin: auto;
            width: 100%;
            max-width: 320px;
            padding: 20px;

            border: 2px solid #f5f5f5;
            border-radius: 8px;
            transition: all .3s ease;
            cursor: pointer;
            text-decoration: none;

            * {
                font-size: 23px;
                font-weight: 500;
                color: #333;
            }

            > div {
                div:first-child {
                    display: grid;
                    place-content: center;

                    background-color: var(--temp-colour);
                    height: 53px;
                    border-radius: 5px;

                    margin-bottom: 20px;
                }
            }

            &--accounting {
                --temp-colour: #eff8f6;
            }

            &--auditing {
                --temp-colour: #fbe7dc;
            }

            &--legal {
                --temp-colour: #efeff9;
            }

            &:hover {
                border-color: var(--temp-colour);
                background-color: var(--temp-colour);
            }
        }



        @media only screen and (max-width: 1000px) {
            grid-auto-flow: row;
            grid-template-columns: 1fr;

            .temp__nav {
                max-width: 500px;
                padding: 12px;

                * {
                    font-size: 20px;
                }

                > div {
                    div:first-child {
                        height: 42px;
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    //.row {
    //    //display: grid;
    //    //grid-auto-flow: column;
    //    //grid-template-columns:
    //    //    [full-start] var(--full)
    //    //    [content-start] var(--content) [content-end]
    //    //    var(--full) [full-end];
    //    //
    //    //> * {
    //    //    grid-column: full;
    //    //}
    //    //
    //    //.content {
    //    //    grid-column: content;
    //    //}
    //}

    .split {
        container-type: inline-size;
        position: relative;
        display: grid;
        grid-auto-flow: row;
        max-width: 100%;

        grid-template-columns: repeat(2, 1fr);

        > div {
            word-break: break-word;
            max-width: 100%;
        }

        //> *:first-child {
        //    width: min-content;
        //}

        //&--left {
        //    grid-template-columns: minmax(35ch, 1fr) max-content;
        //}
        //
        //&--right {
        //    grid-template-columns: max-content minmax(35ch, 1fr);
        //}
    }

    //@container (width < 1000px) {
    @media only screen and (max-width: 1000px) {
        .split {
            grid-template-columns: 1fr;
        }
    }
}
