@import "./legacy/breakpoints";

.bp-widget-web {
    z-index: 1001 !important;
}

iframe.bp-widget-side {
    top: 5rem !important;
    height: calc(100% - 5rem) !important;

    @media only screen and (max-width: $Mobile) {
        top: 6.5rem !important;
        height: calc(100% - 6.5rem) !important;
    }
}
