.draft__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    max-height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;

    &--hidden {
        opacity: 0;
        pointer-events: none;
    }
}

.draft__header {
    display: grid;
    grid-template-columns: 1fr min-content;

    background-color: var(--colour-secondary);
    width: 100%;
    padding: 1rem 2rem;

    * {
        display: grid;
        align-content: center;

        color: white;
        font-size: 1.25rem;
    }

    &--button {
        padding: 0.5rem 0.75rem;

        background-color: white;
        color: black;
        font-weight: 400;
        font-size: .9375rem;

        cursor: pointer;
        border-radius: 0.375rem;
    }
}

.draft__body {
    background-color: white;
    overflow-y: scroll;
}
