@import "breakpoints";

.input__faux {
    pointer-events: none;
    height: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    width: 100%;
    position: absolute;

    &--relative {
        position: relative;
    }
}

.input__placeholder {
    &--barely-visible {
        &::placeholder {
            color: #b2b2b2;
        }
    }
}

.input {
    &--small {
        min-height: 0 !important;
        height: 38px;
    }
}

.input__file-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding: .5rem;
    overflow: hidden;

    transition: all .15s ease;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid var(--colour-border);
    background-color: var(--color-primary-background-light);

    label {
        font-size: 14px;
    }

    &--dragging {
        border: 1px solid var(--colour-border-yellow);
        background-color: var(--colour-yellow-background);
    }

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;

        background-color: transparent;
        border: none;
        cursor: pointer;
        opacity: 0;
        margin: 0;

        top: 0;
        left: 0;

        &::file-selector-button {
            pointer-events: none;
        }
    }

    &.disabled {
        border: 0;
        padding: 0;
        max-height: 0;
    }
}
