@import "veis/application";
@import "legacy/application";
@import "tms/application";
@import "default/application";
@import "shared/toasts";
@import "botpress";

html,
body {
    line-height: 1.55;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: #c1c1c1;
        border: 3px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 12px;
        background: #b6b6b6;
        border: 3px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:active {
        border-radius: 12px;
        background: #a4a4a4;
        border: 3px solid transparent;
        background-clip: content-box;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --colour-success: #00D97E;
    --colour-danger: #E63757;
    --colour-warning: #F6C343;
    --colour-info: #39AFD1;
    --colour-bashful: #EDF2F9;
}

*, ::after, ::before {
    box-sizing: border-box;
}

/*************************/
/***** Circular STD *****/
@font-face {
    font-family: 'CircularStd';
    font-weight: 300;
    src: url(../fonts/CircularStd/CircularStd-Book.woff2) format("woff2"),
         url(../fonts/CircularStd/CircularStd-Book.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    font-weight: 400;
    src: url(../fonts/CircularStd/CircularStd-Medium.woff2) format("woff2"),
         url(../fonts/CircularStd/CircularStd-Medium.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    font-weight: 500;
    src: url(../fonts/CircularStd/CircularStd-Bold.woff2) format("woff2"),
         url(../fonts/CircularStd/CircularStd-Bold.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    font-weight: 600;
    src: url(../fonts/CircularStd/CircularStd-Black.woff2) format("woff2"),
         url(../fonts/CircularStd/CircularStd-Black.woff) format("woff");
    font-display: swap;
}

/*************************/
/***** Cerebri Sans *****/
@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 400;
    src: url(../fonts/cerebri-sans/regular.eot);
    src: url(../fonts/cerebri-sans/regular.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 500;
    src: url(../fonts/cerebri-sans/medium.eot);
    src: url(../fonts/cerebri-sans/medium.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 600;
    src: url(../fonts/cerebri-sans/semibold.eot);
    src: url(../fonts/cerebri-sans/semibold.woff) format("woff");
    font-display: swap;
}
