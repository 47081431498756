form {
    input, select, textarea,
    .collapsable__content {
        width: 100%;
    }

    //label {
    //    max-width: 100%;
    ////    overflow: hidden;
    //}
}

.form__shareholders {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1.5rem;
    grid-row-gap: 1rem;

    @media only screen and (min-width: 1000px) {
        grid-template-columns: repeat(auto-fit, 100%);
        grid-auto-flow: row;
        grid-row-gap: 0;
    }
}

.form__shareholder {
    position: relative;
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: .5rem;
    border: var(--default-border);
    border-radius: var(--default-border-radius);

    --svg-colour: #000;
    svg {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;

        path {
            transition: all .1s ease;
            fill: var(--svg-colour);
        }
    }

    > div:first-child {
        display: grid;
        place-content: center;
        width: 32px;
        height: 100%;

        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
        }

        background-color: var(--colour-border);
        border-right: var(--default-border);

        &:hover {
            background-color: #ededf6;
        }
    }

    > div:nth-child(2), > div:nth-child(4) {
        position: absolute;
        right: 0;
        width: 2rem;
    }

    > div:nth-child(2) {
        position: absolute;
        top: 0;

        border: var(--default-border);
        border-radius: 0 0 0 var(--default-border-radius);
        background-color: var(--colour-border);

        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        line-height: 28px;
    }

    > div:nth-child(3) {
        display: none;
    }

    > div:nth-child(4) {
        display: grid;
        place-content: center;
        bottom: 0;
        height: 2rem;

        cursor: pointer;
        border: var(--default-border);
        border-radius: var(--default-border-radius) 0 0 0;
        border-color: var(--colour-warning);
        background-color: var(--colour-warning);

        &:hover {
            --colour-warning: #e5b234;
        }
    }

    > div:last-child {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
        grid-gap: .25rem;

        > div {
            display: grid;
            align-items: center;
            grid-auto-flow: column;
            place-content: start;
            grid-gap: .5rem;

            padding-right: 2rem;
            white-space: nowrap;
        }
    }

    @media only screen and (min-width: 1000px) {
        grid-template-columns: 32px auto 32px;
        background-color: white;

        > div:first-child, > div:last-child {
            display: none;
        }

        > div:nth-child(2), > div:nth-child(4) {
            position: relative;
            border-radius: 0;
        }

        > div:nth-child(2) {
            display: grid;
            place-content: center;
        }

        > div:nth-child(3) {
            display: grid;
            grid-template-columns: 1fr 1fr 200px;

            > div {
                display: grid;
                grid-template-columns: 20px 1fr;
                align-items: center;
                place-content: start;
                grid-gap: .5rem;
                white-space: nowrap;

                input {
                    border: none;
                    min-height: 32px;
                    padding: 0;
                    width: 100%;
                }
            }
        }

        border-bottom: 0;
        border-radius: 0;

        &:first-child {
            border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
        }

        &:last-child {
            border-bottom: var(--default-border);
            border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
        }
    }

    &--disabled {
        transition: background-color 0s ease;
        background-color: var(--colour-disabled);
        pointer-events: none;

        > div:first-child, > div:nth-child(4) {
            opacity: 0;
        }
    }
}
