.bg-danger {
    &, * { color: white; }
    background-color: var(--colour-danger);
}

.bg-warning {
    background-color: var(--colour-warning);
}

.bg-info {
    &, * { color: white; }
    background-color: var(--colour-info);
}
