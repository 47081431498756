.list__item {
    display: grid;
    grid-template-columns: 1fr 200px 125px 100px;
    grid-gap: 1rem;
    padding: .85rem 1rem;

    color: var(--default-font-colour);
    font-size: 13px;
    font-weight: 300;
    font-family: "roboto",sans-serif;

    transition: all .15s ease;
    background-color: #fff;
    border-bottom: 1px solid #edf2f9;
    cursor: pointer;

    > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:last-child {
        border-bottom: 1px solid transparent;
    }

    &:hover {
        background-color: #f0f2f5;
    }

    &--header {
        background-color: #f9fbfd;
        cursor: unset;

        color: #95aac9;
        font-weight: 600;
        font-size: 14px;

        &:hover {
            background-color: #f9fbfd;
        }
    }
}
