.input__group {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &::file-selector-button {
        pointer-events: none;
    }

    &:disabled {
        cursor: auto;
    }
}

.radio {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;
    position: relative;

    padding: var(--default-font-padding);
    min-width: min-content;
    width: fit-content;
    max-width: 450px;

    transition: all 0s ease;
    background-color: white;
    border: var(--default-border);
    border-radius: var(--default-border-radius);
    cursor: pointer;

    &:hover:not(&--checked) {
        background-color: #f3f3f6;
    }

    &--checked {
        * {
            color: white;
            transition: all 0s ease;
        }
        border-color: var(--colour-primary);
        background-color: var(--colour-primary);
    }
}

.radio label {
    margin: 0;
}

.radio input {
    appearance: none;
    position: absolute;
    padding: 0;
    margin: 0;

    background-color: transparent;
    border: none;

    width: 100%;
    height: 100%;
}
