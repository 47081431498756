@import "breakpoints";

@mixin shared-card-style() {
    position: relative;
    cursor: pointer;
    background: white;

    // border: 2px solid ${ p => p.color || "#D7DFE9" };
    border: 2px solid var(--colour-border);

    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    pointer-events: auto;

    transition: all .2s ease;

    text-align: left;
}

@mixin shared-toggle-box() {
    .toggle-box {
        position: absolute;

        right: 1rem;
        top: 13px;

        border-radius: 4px;
        background: #FFFFFF;
        border: 2px solid var(--colour-border);
        box-sizing: border-box;

        height: 32px;
        width: 32px;

        margin: auto 0;
        transition: all .25s ease;

        > span {
            transition: all .25s ease;
        }

        &.selected {
            > span {
                display: inline-block;
                transform: translate(75%, -15%) rotate(45deg);
                height: 28px;
                width: 14px;
                border-bottom: 7px solid #78b13f;
                border-right: 7px solid #78b13f;
            }

            @media only screen and (max-width: 1200px) {
                > span {
                    transform: translate(65%, -20%) rotate(45deg);
                    height: 20px;
                    width: 12px;
                    border-bottom: 5px solid #78b13f;
                    border-right: 5px solid #78b13f;
                }
            }
        }

        @media only screen and (max-width: 1200px) {
            top: 10px;
            height: 24px;
            width: 24px;
        }
    }
}

@mixin shared-toggle-mechanics() {
    &:hover {
        .toggle-box > span {
            border-bottom: 0 solid transparent;
            transform: translate(0, 0) rotate(0deg);
            border-right: 0 solid transparent;

            &:before, &:after {
                position: absolute;
                left: 11px;
                content: ' ';
                height: 28px;
                width: 7px;
                background-color: #b13f3f;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }

            @media only screen and (max-width: $Laptop) {
                &:before, &:after {
                    position: absolute;
                    left: 8px;
                    content: ' ';
                    height: 20px;
                    width: 5px;
                    background-color: #b13f3f;
                }
            }
        }
    }
}

.funnel-stage-header {
    position: absolute;

    top: 96px;

    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    z-index: 0;

    @media only screen and (max-width: $Mobile) {
        width: calc(100% - 4rem);
        height: 40px;
        top: 80px;
    }
}

.funnel-stage-header .funnel-stage-position {
    padding: 0 1rem;
    max-width: 22rem;

    margin: 0 .5rem;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;

    line-height: 56px;
    font-weight: 500;
    font-size: 30px;

    color: #b6bece;
    transition: all .25s ease;

    span:first-child {
        font-size: 40px;
    }

    &:not(.current) {
        @media only screen and (max-width: $Laptop) {
            max-width: 4.5rem;

            color: transparent;

            span:first-child {
                color: #b6bece;
            }

            &.finished span:first-child {
                color: #77a6c3;
            }
        }

        @media only screen and (max-width: $Mobile) {
            max-width: 2.25rem;
        }
    }

    &.current {
        color: #2b506b;
    }

    &.finished {
        color: #77a6c3;
    }

    @media only screen and (max-width: $Mobile) {
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);

        padding: 0 .5rem;
        border: 2px solid rgba(215,223,233,0.7);
        line-height: 36px;
        font-size: 20px;

        span:first-child {
            font-size: 24px;
            margin-right: .25rem;
        }
    }
}

.funnel-container {
    h5 {
        font-size: 1.4rem;

        @media only screen and (max-width: $Tablet) {
            font-size: 1.25rem;
        }
    }

    label {
        font-weight: 300;
        color: var(--colour-grey);
        font-size: 1.25rem;

        @media only screen and (max-width: $Tablet) {
            font-size: 1.1rem;
        }
    }

    small {
        font-style: normal;
        font-size: 1rem;
        line-height: 1.5625rem;
        letter-spacing: -0.03125rem;
        font-weight: 300;

        color: var(--colour-light);
    }
}

.funnel-container-bottom-buffer {
    > div {
        margin-bottom: 3rem;

        @media only screen and (max-width: $Tablet) {
            margin-bottom: 1.5rem;
        }
    }

    > div:last-child {
        margin-bottom: 5rem;
    }
}

.selected-service-container {
    margin-bottom: .33rem;

    @media only screen and (min-width: $Tablet) {
        margin-top: -2.5rem;
    }

    @media only screen and (min-width: $Mobile) {
        margin-bottom: 1rem;
    }

    @media only screen and (max-width: $Mobile) {
        margin-top: -2.5rem;
    }
}

.funnel-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: fixed;
    width: 100%;
    z-index: 1;

    bottom: calc(1.5rem + 5px);
    transition: all .15s ease;

    @media only screen and (max-width: $Laptop) {
        bottom: calc(1rem);
    }

    @media only screen and (max-width: $TabletSmall) {
        bottom: 0;
    }
}

@mixin shared-button-style() {
    text-align: center;
    font-family: var(--default-fonts);
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 1px;
    height: 62px;
    cursor: pointer;

    transition: all .25s ease;

    color: #244A66;
    background-color: white;
    min-width: 256px;
    padding: 0 2rem;
    border: 2px solid #244A66;
    user-select: none;

    &:hover {
        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    }

    &:not(.secondary, .faded) {
        background-color: #244A66;
        color: white;
    }

    &:not(.active) {
        pointer-events: none;
    }

    &.faded {
        color: #8d8d8d;
    }

    &:not(.faded).final {
        border: 2px solid #24665e;
        background-color: #24665e;
    }

    &:not(.visible) {
        background-color: transparent;
        color: transparent;
        border: 2px solid transparent;
        cursor: auto;
        &:hover {
            box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0);
            z-index: -1;
        }
    }

    @media only screen and (max-width: $Laptop) {
        height: 56px;
    }

    @media only screen and (max-width: $TabletSmall) {
        border: 0 solid transparent;
        border-top: 2px solid var(--colour-secondary);
        min-width: 50vw;
        font-size: 22px;
    }
}

.funnel-nav-return {
    @include shared-button-style();

    margin-right: -1px;
    border-radius: 6px 0 0 6px;

    @media only screen and (max-width: $TabletSmall) {
        border-radius: 0 0 0 0;
        margin-right: 0;
    }
}

.funnel-nav-continue {
    @include shared-button-style();

    margin-left: -1px;
    border-radius: 0 6px 6px 0;

    @media only screen and (max-width: $TabletSmall) {
        border-radius: 0 0 0 0;
        margin-left: 0;
    }
}

.price-highlight {
    display: inline-block;
}

.price-card-container {
    background: #FFFFFF;
    border: 1px solid var(--colour-border);
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    pointer-events: auto;
    user-select: none;
    cursor: pointer;

    width: 100%;
    transition: all .33s ease;

    &:hover {
        .price-card-header {
            background: #2B506B;
        }
    }

    &.selected {
        .price-card-header {
            background: #2B506B;
        }

        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.3);
    }

    margin: 1.5rem 0;
}

.price-card-header {
    background: #77A6C3;
    border: 2px solid var(--colour-border);
    box-sizing: border-box;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.16);
    border-radius: 6px;

    font-weight: 400;
    letter-spacing: -0.500211px;
    color: #FFFFFF;

    user-select: none;
    transition: all .15s ease;

    text-align: left;
    padding: 0 1rem;
    line-height: 48px;
    font-size: 28px;

    @media only screen and (max-width: $Mobile) {
        font-size: 24px;
    }

    @media only screen and (max-width: $MobileSmall) {
        font-size: 22px;
    }
}

.price-card-body {
    padding: 1rem;

    h2, p {
        text-align: left;
    }

    h2 {
        margin: 0 0 .5rem;
        font-size: 22px;
        line-height: 36px;
    }

    @media only screen and (max-width: $MobileSmall) {
        p {
            margin: .25rem 0;
        }
    }
}

.price-subscription {
    &:hover {
        .toggle-container {
            background-color: #e3e9f0;
            border-radius: 6px;
        }
    }
}

.price-card-body, .service-card {
    .toggle-container {
        font-size: 28px;
        line-height: 62px;
        font-weight: 400;
        color: #282828;

        margin: 0 -.5rem;
        padding: 0 .5rem;

        transition: all .25s ease;

        @media only screen and (max-width: $Laptop) {
            font-size: 22px;
            line-height: 36px;
        }

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
            background-color: #e3e9f0;
            border-radius: 6px;
        }

        @media only screen and (max-width: $Mobile) {
            font-size: 20px;
        }
    }
}

.price-card-body, .service-card, .associate-container {
    .toggle-box {
        border-radius: 4px;
        background: #FFFFFF;
        border: 2px solid var(--colour-border);
        box-sizing: border-box;

        height: 32px;
        width: 32px;

        margin: auto 0;

        &.selected {
            > span {
                display: inline-block;
                transform: translate(75%, -55%) rotate(45deg);
                height: 28px;
                width: 14px;
                border-bottom: 7px solid #78b13f;
                border-right: 7px solid #78b13f;
            }

            @media only screen and (max-width: $Laptop) {
                > span {
                    transform: translate(65%, -45%) rotate(45deg);
                    height: 20px;
                    width: 12px;
                    border-bottom: 5px solid #78b13f;
                    border-right: 5px solid #78b13f;
                }
            }
        }

        @media only screen and (max-width: $Laptop) {
            height: 24px;
            width: 24px;
        }
    }
}

.funnel-default-card {
    @include shared-card-style();

    min-width: calc(50%);
    max-width: calc(50%);

    margin: 0 auto;
    padding: 1rem;
    cursor: auto;

    @media only screen and (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
        margin: 0;
    }

    h5 {
        margin: .5rem 0 1.5rem;
    }
}

.funnel-info-box {
    p {
        color: #727272;
    }

    li {
        font-weight: 300;
        font-size: 1.1rem;

        @media only screen and (min-width: $Mobile) {
            font-size: 1.25rem;
        }
    }

    strong {
        font-weight: 400;
    }
}

.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;

    // Emulates flex gap
    margin-right: -10px;

    > div {
        // Emulates flex gap
        margin-right: 12px;
        margin-bottom: 10px;
    }
}

.tag-item {
    display: inline-block;
    max-width: 100%;
    cursor: pointer;
    user-select: none;

    border: solid 1px #2B506B;
    border-radius: 10px;
    padding: 2px 8px 0;

    color: #2B506B;
    font-size: 14px;
    line-height: 14px;
    vertical-align: bottom;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: all .1s ease;

    &.selected {
        color: white;
        background-color: #2B506B;
    }
}

.type-container {
    position: absolute;
    top: 0;

    width: calc(100% - 2rem);
    z-index: -1;
    height: 3rem;

    transform: translateY(-2.5rem);
    text-align: center;

    &:not(&-toggleable) {
        display: none;
    }

    @media only screen and (max-width: $Mobile) {
        height: 2.5rem;
        transform: translateY(-2.25rem);
    }

    .type-divider {
        background-color: var(--colour-border);
        width: 1px;
        height: 2rem;
        margin-top: .25rem;
    }

    .type-buttons {
        background: #FFFFFF;
        border: 1px solid var(--colour-border);
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);

        display: inline-flex;
        flex-direction: row;
    }

    .type-button-container {
        height: 3rem;
        display: flex;
        flex-direction: row;
    }

    .type-button {
        cursor: pointer;
        user-select: none;

        line-height: 2.5rem;
        font-size: 18px;
        font-weight: 400;
        font-family: var(--default-fonts);

        color: #aaaaaa;
        padding: 0 1.5rem;

        &:hover {
            background: #F0F0F0;
            color: #282828;
        }

        &.active {
            color: #282828;
        }

        @media only screen and (max-width: $Mobile) {
            line-height: 2.25rem;
            padding: 0 1rem;
            font-size: 14px;
        }
    }
}

.card-icon {
    position: absolute;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: top 50% right 50%;
    background-size: contain;

    height: 54px;
    width: 54px;

    margin: .25rem .5rem;

    @media only screen and (max-width: $Laptop) {
        margin: 0 .5rem;
        height: 48px;
        width: 48px;
    }

    @media only screen and (max-width: $Tablet) {
        top: 1rem;
        right: .5rem;
    }

    @media only screen and (max-width: $TabletSmall) {
        top: .25rem;
    }

    @media only screen and (max-width: $Mobile) {
        top: 1rem;
    }

    pointer-events: none;
}

.funnel-big-card {
    @include shared-card-style();

    &.no-click {
        cursor: auto;
    }

    margin: 2rem auto;

    min-width: calc(50%);
    max-width: calc(50%);

    @media only screen and (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
        margin: 1rem 0;
    }

    @media only screen and (min-width: $Tablet) {
        @include shared-toggle-mechanics();
    }
}

.service-card {
    @include shared-card-style();

    user-select: none;
    height: 66px;
    min-width: calc(50% - 4rem);
    max-width: calc(50% - 4rem);
    margin: 2rem auto;

    @media only screen and (max-width: $Laptop) {
        height: 52px;
    }

    @media only screen and (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
        margin: 1rem 0;
    }

    @include shared-toggle-box();
    @include shared-toggle-mechanics();
}

.service-card-header {
    font-size: 24px;
    line-height: 62px;
    // font-family: "CircularStdBold", sans-serif;
    font-weight: 500;
    //color: #929292;
    color: #282828;

    transition: all .25s ease;

    @media only screen and (max-width: 1200px) {
        font-size: 17px;
        line-height: 48px;
    }

    max-width: calc(100% - 48px - 1rem);

    &.no-image {
        max-width: 100%;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1rem;

    display: block;
}

.service-card-sales {
    border-radius: 6px;
    background-color: var(--color-primary-background);
    border-color: var(--colour-border-darkened);
}

.service-card-description {
    padding: 1rem;

    &.no-click {
        cursor: auto;
    }

    @media only screen and (max-width: $Laptop) {
        p {
            text-align: left;
        }
    }
}

.associate-container {
    margin: 0 -2rem;

    @media only screen and (max-width: $Mobile) {
        margin: 0 -1rem;
    }

    @include shared-toggle-box();

    .toggle-container {
        margin: 0 2rem 1rem;

        @media only screen and (max-width: $Mobile) {
            margin: -.5rem .5rem 1rem;
            padding-right: 3.5rem;
            white-space: normal;
            line-height: 28px;

            .toggle-box {
                top: calc(50% - 12px);
            }

            .toggle-box > span {
                transform: translate(50%, -20%) rotate(45deg);
            }
        }
    }
}
