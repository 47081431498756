& {
    --pdf-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
    --controls-rounded-corners: 4px;
}

.pdf {
    input, button {
        font: inherit;
    }

    header {
        background-color: #323639;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        padding: 20px;
        color: white;
    }

    h1 {
        font-size: inherit;
        margin: 0;
    }
}

.pdf__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin: 10px 0;
    //padding: 10px;
}

.pdf__container--load {
    margin-top: 1em;
    color: white;
}

.pdf__container__document {
    width: 100%;
    max-width: calc(100% - 2em);
    //margin: 1em 0;

    .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .react-pdf__Page {
        //margin: 1em 0;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    }

    .react-pdf__message {
        padding: 20px;
        color: white;
    }
}

.react-pdf__Document {
    max-width: 100%;
    border-radius: 12px;
}

.react-pdf__Page {
    margin: 0;
}

.react-pdf__Document {
    position: relative;
    pointer-events: none;
}

.react-pdf__Page {
    pointer-events: auto;
}

.pdf__tool-controls,
.pdf__page-controls {
    pointer-events: auto;
    background: white;
    border-radius: var(--controls-rounded-corners);
    z-index: 2;
}

.pdf__tool-controls {
    box-shadow: 0 5px 10px 0 rgba(16, 36, 94, .2);
    width: min-content;
    white-space: nowrap;
    margin: auto;

    button {
        display: inline-grid;
        place-content: center;

        svg {
            width: 24px;
            height: 24px;

            path {
                transition: all .1s ease;
                fill: #444444;
            }
        }

        &:disabled svg path {
            fill: #b2b2b2;
        }
    }
}

.pdf__page-controls {
    position: sticky;
    bottom: 5%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%);
    transition: opacity ease-in-out .2s;
    box-shadow: var(--pdf-shadow);
}

.pdf__tool-controls span,
.pdf__page-controls span {
    font: inherit;
    font-size: .8em;
    padding: 0 .5em;
}

.pdf__tool-controls button,
.pdf__page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: .8em;
    border-radius: var(--controls-rounded-corners);
}

.pdf__tool-controls button:enabled:hover,
.pdf__page-controls button:enabled:hover {
    cursor: pointer;
}

.pdf__tool-controls button:enabled:hover,.page-controls button:enabled:focus,
.pdf__page-controls button:enabled:hover,.page-controls button:enabled:focus {
    background-color: #e6e6e6;
}

.pdf__tool-controls button:first-child,
.pdf__page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pdf__tool-controls button:last-child,
.pdf__page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pdf__page-controls:hover {
    opacity: 1;
}

.react-pdf__Page:hover + .pdf__page-controls {
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    & {
        --pdf-shadow: 0 10px 40px 0 rgba(16, 36, 94, .2);
    }

    .pdf__page-controls {
        opacity: 1;
    }
}
