button {
    max-width: 100%;
}

button.btn, .button {}

.btn {
    display: inline-block;
    position: relative;
    padding: 0.5rem 0.75rem;
    min-height: 32px;
    z-index: 0;

    line-height: 1.5;
    letter-spacing: 0.05rem;
    white-space: nowrap;

    cursor: pointer;
    user-select: none;
    transition: all .25s ease;
    outline: none;

    --button-font-colour: white;
    --button-background-colour: black;
    --button-background-loading-colour: black;
    --button-border-colour: black;
    --default-font-weight: 500;

    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);

    &--block {
        display: block;
        width: 100%;
    }

    &--width-360 {
        width: 100%;
        max-width: 360px;
    }

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all .25s ease;

        background-image: linear-gradient(
                45deg,
                var(--button-background-loading-colour) 25%,
                transparent 25%,
                transparent 50%,
                var(--button-background-loading-colour) 50%,
                var(--button-background-loading-colour) 75%,
                transparent 75%,
                transparent
        );

        background-size: 2rem 2rem;
        animation: 1s linear infinite loading-stripes;

        @keyframes loading-stripes {
            0% {
                background-position: 2rem 0;
            }
            to {
                background-position: 0 0;
            }
        }
    }

    &[data-loading="true"] {
        &:before {
            opacity: 1;
        }
    }

    &--primary {
        --button-background-loading-colour: #183c56;
        --button-background-colour: var(--colour-primary);
        --button-border-colour: var(--colour-primary);

        &:hover {
            --button-background-colour: #183c56;
            --button-border-colour: #183c56;
        }

        &[data-loading="true"] {
            --button-background-colour: var(--colour-primary);
            --button-border-colour: var(--colour-primary);
        }
    }

    &--light {
        --button-background-colour: var(--colour-disabled);
        --button-border-colour: var(--colour-disabled);
        --button-font-colour: #262626;

        &:hover {
            --button-background-colour: var(--colour-light-grey);
            --button-border-colour: var(--colour-light-grey);
        }
    }

    &--success {
        --button-background-loading-colour: var(--colour-success-alt);
        --button-background-colour: var(--colour-success-alt);
        --button-border-colour: var(--colour-success-alt);

        &:hover {
            --button-background-colour: #00AE66;
            --button-border-colour: #00AE66;
        }
    }

    &--bashful {
        --button-font-colour: var(--colour-primary);
        --button-background-colour: transparent;
        --button-border-colour: transparent;

        &:hover {
            --button-border-colour: var(--colour-primary);
        }
    }

    &:disabled {
        cursor: auto;

        --button-background-loading-colour: #eaebf5;
        --button-background-colour: var(--colour-disabled);
        --button-border-colour: var(--colour-disabled);
        --button-font-colour: var(--colour-disabled-font);

        &[data-loading="true"] {
            --button-background-colour: var(--colour-disabled);
            --button-border-colour: var(--colour-disabled);
        }
    }

    color: var(--button-font-colour);
    background-color: var(--button-background-colour);
    border: 1px solid var(--button-border-colour);
    border-radius: 0.375rem;
}

.btn--toggle, .button--toggle {
    --button-font-colour: #283e59;
    --button-background-colour: var(--colour-bashful);
    border: none;

    &:hover {
        --button-background-colour: #d0ddef;
    }

    &[data-toggled="true"] {
        color: white;
        --button-background-colour: #6e84a3;
    }
}
