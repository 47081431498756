@import "breakpoints";

@mixin grid-sizing($p) {
    [class~=#{$p + "1"}] { width: 8.3333333333%; flex: 0 0 8.3333333333%; }
    [class~=#{$p + "2"}] { width: 16.6666666667%; flex: 0 0 16.6666666667%; }
    [class~=#{$p + "3"}] { width: 25%; flex: 0 0 25%; }
    [class~=#{$p + "4"}] { width: 33.3333333333%; flex: 0 0 33.3333333333%; }
    [class~=#{$p + "5"}] { width: 41.6666666667%; flex: 0 0 41.6666666667%; }
    [class~=#{$p + "6"}] { width: 50%; flex: 0 0 50%; }
    [class~=#{$p + "7"}] { width: 58.3333333333%; flex: 0 0 58.3333333333%; }
    [class~=#{$p + "8"}] { width: 66.6666666667%; flex: 0 0 66.6666666667%; }
    [class~=#{$p + "9"}] { width: 75%; flex: 0 0 75%; }
    [class~=#{$p + "10"}] { width: 83.3333333333%; flex: 0 0 83.3333333333%; }
    [class~=#{$p + "11"}] { width: 91.6666666667%; flex: 0 0 91.6666666667%; }
    [class~=#{$p + "12"}] { width: 100%; flex: 0 0 100%; }

    [class~=#{$p + "1"}], [class~=#{$p + "2"}], [class~=#{$p + "3"}], [class~=#{$p + "4"}],
    [class~=#{$p + "5"}], [class~=#{$p + "6"}], [class~=#{$p + "7"}], [class~=#{$p + "8"}],
    [class~=#{$p + "9"}], [class~=#{$p + "10"}], [class~=#{$p + "11"}], [class~=#{$p + "12"}] {
        left: auto;
        right: auto;
    }
}

#__next:not(:has(#__veis)) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    position: relative;
    margin: 0 auto;
    max-width: min(100vw, 1280px);
    width: 100vw;
    z-index: 0;

    @media only screen and (min-width: $Mobile) {
        width: 85vw;
    }

    @media only screen and (min-width: $LaptopLarge) {
        width: 80vw;
    }
}

.valign {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.justify-content-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

[class*="col"] .row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.row {
    margin-left: auto;
    margin-right: auto;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    [class*="col-"] {
        position: relative;
        float: left;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 0.75rem;
        min-height: 1px;
    }

    @include grid-sizing("col-");

    // [Extra Small] Screens larger than 600px
    @media only screen and (min-width: $Mobile) {
        @include grid-sizing("col-xs-");
    }

    // [Small] Screens larger than 800px
    @media only screen and (min-width: $TabletSmall) {
        @include grid-sizing("col-sm-");
    }

    // [Medium] Screens larger than 1000px
    @media only screen and (min-width: $Tablet) {
        @include grid-sizing("col-md-");
    }

    // [Large] Screens larger than 1200px
    @media only screen and (min-width: $Laptop) {
        @include grid-sizing("col-lg-");
    }

    // [Extra Large] Screens larger than 1400px
    @media only screen and (min-width: $LaptopLarge) {
        @include grid-sizing("col-xl-");
    }

    // [Huge] Screens larger than 1600px
    @media only screen and (min-width: $Desktop) {
        @include grid-sizing("col-hu-");
    }

    // [Massive] Screens larger than 2000px
    @media only screen and (min-width: $DesktopLarge) {
        @include grid-sizing("col-ma-");
    }
}
