.toggle__container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &--space-between {
        justify-content: space-between;
    }
}

.toggle__button--slider {
    position: relative;
    display: flex;
    flex-direction: row;

    border-radius: 5px;
    border: 1px solid var(--colour-border);

    cursor: pointer;
    overflow: hidden;

    div {
        width: 40px;
        padding: 2px 4px;
        text-align: center;
        user-select: none;
    }

    &::before {
        position: absolute;
        content: '';
        background-color: #ff3e01;
        height: 100%;
        width: 40px;

        top: 0;
        left: 0;

        transition: all .25s ease;
    }

    &.enabled::before {
        left: calc(100% - 40px);
        background-color: #59b95e;
    }

    &.disabled::before {
        background-color: var(--colour-grey);
    }

    &-dynamic {
        div {
            width: min-content;
        }

        &::before {
            content: attr(data-content);
            color: transparent;
            width: min-content;
            padding: 2px 4px;
            background-color: var(--colour-light);
        }

        &.enabled::before {
            left: 0;
            transform: translateX(100%);
            background-color: var(--colour-light);
        }
    }
}

