.previews__container {
    display: grid;
    grid-auto-rows: 1fr;
    height: calc(100% - 142px);
}

.previews--maximised {}

.previews--document {
    overflow: hidden;
    border: var(--default-border);
    border-radius: var(--default-border-radius);
}
