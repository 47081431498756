.matrix {
    //display: grid;
    //grid-template-columns: 1.25fr repeat(auto-fit, 1fr);
}

.matrix-head, .matrix-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.matrix-head {
    div {
        font-size: .8125rem;
        font-weight: 400;
        font-style: italic;
        text-align: center;
    }

    @container (max-width: 600px) {
        display: none;
    }
}

.matrix-body {
    height: 2.5rem;

    > div:first-child {
        display: grid;
        align-content: center;
    }

    .radio {
        --default-font-padding: 0.625rem;
        --colour-border: #dddeed;

        transition: all .1s ease;

        &:not(&--checked) {
            background-color: #d9d9d9;
        }

        &:hover {
            filter: brightness(0.8);
        }
    }

    > div:not(:first-child) {
        display: grid;
        place-content: center;
    }

    select {
        width: 100%;
        height: 40px;
    }

    @container (max-width: 600px) {
        margin-top: 1rem;
        grid-template-columns: .5fr 1fr !important;

        > div:not(:first-child) {
            display: none;
        }
    }

    @container (min-width: 600px) {
        select {
            display: none;
        }
    }
}
