@import "breakpoints";

@mixin position-helpers($start, $end, $p) {
    // Positions anything between the two sizes.
    @media only screen and (min-width: $start) and (max-width: $end) {
        [class~=#{"center" + $p}] {
            text-align: center;
        }

        [class~=#{"left" + $p}] {
            text-align: left;
        }

        [class~=#{"right" + $p}] {
            text-align: right;
        }
    }

    // Positions anything from the START size and up.
    @media only screen and (min-width: $start) {
        [class~=#{"center" + $p + "-up"}] {
            text-align: center;
        }

        [class~=#{"left" + $p + "-up"}] {
            text-align: left;
        }

        [class~=#{"right" + $p + "-up"}] {
            text-align: right;
        }
    }

    // Positions everything from the END size and down.
    @media only screen and (max-width: $end) {
        [class~=#{"center" + $p + "-down"}] {
            text-align: center;
        }

        [class~=#{"left" + $p + "-down"}] {
            text-align: left;
        }

        [class~=#{"right" + $p + "-down"}] {
            text-align: right;
        }
    }

    // Positions when outside of the two sizes.
    [class~=#{"center" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            text-align: center;
        }
    }

    [class~=#{"left" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            text-align: left;
        }
    }

    [class~=#{"right" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            text-align: right;
        }
    }
}

h1, h2, h3, h4, h5, h6, p, button, div, a {
    transition: opacity .5s ease,
                color .25s ease;
    letter-spacing: .5px;

    &.white {
        color: white;
    }

    --font-size-h1: 1.9rem;
    --font-size-h2: 1.85rem;
    --font-size-h3: 1.75rem;
    --font-size-h4: 1.5rem;
    --font-size-h5: 1.33rem;
    --font-size-h6: 1.25rem;
    --font-size-p: 1.1rem;

    @media only screen and (min-width: $MobileSmall) {
        --font-size-h1: 2.25rem;
        --font-size-h2: 2rem;
    }

    @media only screen and (min-width: $Mobile) {
        --font-size-h1: 3rem;
        --font-size-h2: 2.33rem;
        --font-size-h3: 2rem;
        --font-size-h4: 1.75rem;
        --font-size-h5: 1.5rem;
        --font-size-h6: 1.33rem;
        --font-size-p: 1.25rem;
    }

    @media only screen and (min-width: $Laptop) {
        --font-size-h1: 3.5rem;
        --font-size-h2: 2.55rem;
        --font-size-h3: 2.25rem;
        --font-size-h4: 2rem;
        --font-size-h5: 1.75rem;
        --font-size-h6: 1.5rem;
    }

    &.large {
        --font-size-h1: 1.9rem;
        --font-size-h2: 1.85rem;
        --font-size-h3: 1.75rem;
        --font-size-p: 1.25rem;

        @media only screen and (min-width: $MobileSmall) {
            --font-size-h1: 2.3rem;
            --font-size-h2: 2.25rem;
        }

        @media only screen and (min-width: $Mobile) {
            --font-size-h1: 3.5rem;
            --font-size-h2: 3rem;
            --font-size-h3: 2.2rem;
            --font-size-p: 1.35rem;
        }

        @media only screen and (min-width: $Laptop) {
            --font-size-h1: 3.75rem;
            --font-size-h2: 3.25rem;
            --font-size-h3: 2.4rem;
            --font-size-p: 1.5rem;
        }

        @media only screen and (min-width: $LaptopLarge) {
            --font-size-h1: 4.25rem;
        }

        @media only screen and (min-width: $Desktop) {
            --font-size-h1: 4.75rem;
        }
    }

    &.small {
        --font-size-h1: 1.75rem;
        --font-size-h2: 1.6rem;
        --font-size-h3: 1.5rem;
        --font-size-h4: 1.4rem;
        --font-size-h5: 1.25rem;
        --font-size-h6: 1.1rem;
        --font-size-p: 1rem;

        @media only screen and (min-width: $MobileSmall) {
            --font-size-h1: 2.15rem;
            --font-size-h2: 2rem;
        }

        @media only screen and (min-width: $Mobile) {
            --font-size-h1: 2.66rem;
            --font-size-h2: 2.15rem;
            --font-size-h3: 1.9rem;
            --font-size-h4: 1.66rem;
            --font-size-h5: 1.4rem;
            --font-size-h6: 1.25rem;
            --font-size-p: 1.1rem;
        }

        @media only screen and (min-width: $Laptop) {
            --font-size-h1: 3rem;
            --font-size-h2: 2.33rem;
            --font-size-h3: 2rem;
            --font-size-h4: 1.85rem;
            --font-size-h5: 1.5rem;
            --font-size-h6: 1.33rem;
        }
    }
}

h1, .h1m {
    margin: 1rem 0;
    line-height: 1.55;
    font-weight: 300;
    color: var(--colour-dark);

    &.large {
        line-height: 1.25;
        font-weight: 400;

        @media only screen and (min-width: $Laptop) {
            line-height: 1.2;
        }
    }

    &.small {
        line-height: 1.1;
    }

    font-size: var(--font-size-h1);
}

h2, .h2m {
    margin: 1rem 0;
    line-height: 1.25;
    color: var(--colour-dark);
    font-weight: 300;

    &.large {
        line-height: 1.25;
        font-weight: 400;
    }

    font-size: var(--font-size-h2);
}

h3, .h3m {
    margin: 1rem 0;
    line-height: 1.25;
    color: var(--colour-dark);
    font-weight: 300;

    &.large {
        line-height: 1.25;
        font-weight: 400;
    }

    font-size: var(--font-size-h3);
}

h4, .h4m {
    margin: 1rem 0;
    line-height: 1.55;
    color: var(--colour-dark);
    font-weight: 300;

    font-size: var(--font-size-h4);
}

h5, .h5m {
    margin: 1rem 0;
    line-height: 1.55;
    font-weight: 400;

    font-size: var(--font-size-h5);
}

h6, .h6m {
    margin: 1rem 0;
    line-height: 1.55;
    font-weight: 500;
    text-transform: uppercase;

    font-size: var(--font-size-h6);

    &.synega {
        color: var(--colour-secondary);
    }
}

p {
    color: var(--colour-dark-grey);
    line-height: 1.55;
    margin: 1rem 0;
    font-weight: 300;

    &.large {
        color: var(--colour-dark);
    }

    font-size: var(--font-size-p);

    strong {
        font-weight: 400;
    }
}

a {
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--colour-link);

    &.coloured {
        color: var(--colour-link-light) !important;
    }

    &:hover {
        text-decoration: underline;
        color: var(--colour-link-highlight);
    }
}

*::placeholder {
    transition: opacity .15s ease,
                color .15s ease;
}

*:focus::placeholder {
    opacity: 0;
}

footer {
    h6 {
        color: var(--colour-dark);
    }

    a {
        color: var(--colour-grey);

        &:hover {
            text-decoration: none;
        }
    }
}

span {
    &.header {
        color: var(--colour-primary);

        --font-size-span: 0.9rem;

        @media only screen and (min-width: $MobileSmall) {
            --font-size-span: 1.1rem;
        }

        @media only screen and (min-width: $Laptop) {
            --font-size-span: 1.2rem;
        }

        font-size: var(--font-size-span);
        text-transform: uppercase;
        line-height: 1.55;
        font-weight: 500;
    }
}

ul, ol {
    &.contain {
        display: inline-block;
        min-width: min(100%, 350px);
    }

    li {
        text-align: left;
        font-size: var(--font-size-p);
        font-weight: 300;
    }

    &.large {
        line-height: 1.55;
        --font-size-p: 1.25rem;

        @media only screen and (min-width: $Mobile) {
            --font-size-p: 1.35rem;
        }

        font-size: var(--font-size-p);
    }
}

// Spacing based on situations
@include position-helpers("0px", "9999px", "");
@include position-helpers("0px", $Mobile, "-mobile");
@include position-helpers($Mobile, $Laptop, "-tablet");
@include position-helpers($Laptop, "9999px", "-desktop");
