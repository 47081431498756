.alert--bashful {
    --background-colour-code: var(--colour-primary);
    --border-colour-code: transparent;

    --default-code-colour: white;
    background-color: var(--colour-bashful);
}

.alert--info {
    --default-font-colour: white;
    background-color: var(--colour-info);
}

.alert--success {
     background-color: var(--colour-success);
 }

.alert--warning {
    background-color: var(--colour-warning);
}

.alert--danger {
    --default-font-colour: white;
    background-color: var(--colour-danger);
}
