.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6,
.p, p, .label, label, div, section, main {
    transition: opacity .5s ease,
                color .25s ease;

    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);
    line-height: 1.5;
    color: var(--default-font-colour);

    //outline: none;
    //margin: 0;

    span {
        display: inline-block;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
    --default-font-weight: 500;
    --default-font-colour: #333;
    line-height: 1.25;
}

.code, code, .pre, pre, p code {
    font-family: "Consolas", "Monaco", monospace;
    background-color: var(--background-colour-code);
    color: var(--default-code-colour);
    padding: 2px 4px;
    margin: 0 2px;
    border: 1px solid var(--border-colour-code);
    border-radius: 4px;
    font-size: 0.95em;
    overflow-x: auto;
}

.pre, pre {
    width: min-content;
}

.emphasis {
    color: var(--colour-emphasis);
}

@mixin typography($prefix: "") {
    .#{$prefix}h1, h1 {
        font-size: 2.125rem;
        margin-bottom: 1.125rem;
        --default-font-family: "Open Sans", Arial, sans-serif;
    }

    .#{$prefix}h2, h2 {
        font-size: 2rem;
        margin-bottom: 1.125rem;
    }

    .#{$prefix}h3, h3 {
        font-size: 1.375rem;
        margin-bottom: 1rem;
    }

    .#{$prefix}h4, h4 {
        font-size: 1.375rem;
        margin-bottom: .84375rem;
    }
}

@include typography();

@media only screen and (width > 1000px) {
    @include typography("lg-");
}

@media only screen and (width <= 1000px) {
    @include typography("md-");
}

@media only screen and (width <= 600px) {
    @include typography("sm-");
}

.label--wrap label, label.label--wrap {
    &[for] {
        width: 100%;
        display: block;
        white-space: normal;
    }
}

.label, label {
    margin-bottom: 0.5rem;

    &[for] {
        display: grid;
        grid-gap: .5rem;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, min-content);
        width: min-content;
        max-width: 100%;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span:last-child {
            display: inline-grid;
            align-items: center;
            place-content: center;
            height: 100%;
            width: min-content;

            white-space: nowrap;
            color: var(--colour-muted);
        }
    }
}

//@media only screen and (max-width: 1000px) {
//@container (width < 1000px) {
    .label, label {
        &[for] {
            width: 100%;
            display: block;
            white-space: normal;
            overflow: unset;
            line-height: 1.25;
        }
    }
//}

.left-aligned {
    text-align: left;
}

.right-aligned {
    text-align: right;
}

.centre-aligned, .center-aligned {
    text-align: center;
}
