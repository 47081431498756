$chevron-down: "../../assets/icons/chevron-down.svg";
$password-hidden: "../../assets/icons/eye.svg";
$password-visible: "../../assets/icons/eye-hide.svg";

label[for] {
    cursor: pointer;
}

select, option, input, textarea {
    all: unset;
    box-sizing: border-box;

    font-family: var(--default-font-family);
    font-weight: var(--default-font-weight);
    font-size: var(--default-font-size);
    color: var(--default-font-colour);
    line-height: 1.5;

    &::placeholder {
        color: #b1c2d9;
    }

    //&:valid {
    //    border-color: var(--colour-light-green);
    //}

    &[data-blurred="true"]:invalid:not(&:focus, &:disabled) {
        border-color: #f78da7;
    }

    &:focus:not(&:disabled), &:active:not(&:disabled) {
        border-color: var(--colour-primary);
    }

    &:disabled {
        background-color: var(--colour-disabled);
    }
}

select {
    padding: var(--default-font-padding);
    padding-right: 2rem;

    cursor: pointer;
    background-image: url($chevron-down);
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 24px 24px;

    &:disabled {
        cursor: auto;
    }
}

textarea {
    min-height: 5rem;
    max-height: 20rem;
    resize: none;
}

input, textarea {
    padding: var(--default-font-padding);
    min-height: 43px;

    &[type="date"] {
        position: relative;
        //width: 100%;
        overflow: hidden;

        &::-webkit-calendar-picker-indicator {
            position: absolute;
            top: -150%;
            left: -150%;
            width: 300%;
            height: 300%;
            cursor: pointer;
            opacity: 0;
        }
    }
}

select, input, textarea {
    display: block;

    background-color: #fff;
    background-clip: padding-box;
    border: var(--default-border);
    border-radius: var(--default-border-radius);
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

.input__group {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.input {
    position: relative;
}

.input--password {
    position: absolute;
    right: 0;
    top: 0;

    height: 100%;
    aspect-ratio: 1/1;

    opacity: 50%;
    cursor: pointer;
    transition: opacity .2s ease;
    background-image: url($password-hidden);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% 50%;

    &:hover {
        opacity: 100%;
    }

    &-hidden {
        background-image: url($password-visible);
    }
}

input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &::file-selector-button {
        pointer-events: none;
    }

    &:disabled {
        cursor: auto;
    }
}

.toggle {
    display: grid;
    position: relative;
    transition: all .25s ease;

    &[data-disabled="true"] {
        border-color: var(--colour-disabled-font);
        background-color: var(--colour-disabled);
        color: var(--colour-disabled-font);

        &:before {
            background-color: #909598;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        opacity: 0;
        cursor: pointer;

        &:disabled {
            cursor: auto;
        }
    }
}

.radio {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;
    position: relative;

    padding: var(--default-font-padding);
    height: var(--default-font-padding);
    min-width: min-content;
    width: fit-content;
    max-width: 450px;

    background-color: white;
    border: var(--default-border);
    border-radius: var(--default-border-radius);
    cursor: pointer;

    transition: all 0s ease;

    label {
        margin: 0;
    }

    input {
        appearance: none;
        position: absolute;
        padding: 0;
        margin: 0;

        background-color: transparent;
        border: none;

        width: 100%;
        height: 100%;
    }

    &:hover:not(&--checked) {
        background-color: #f3f3f6;
    }

    &-check {
        --checkbox-size: calc(var(--default-font-padding) * 2);

        &::after {
            position: absolute;
            display: block;
            top: calc(50% - var(--checkbox-size)/2);
            width: var(--checkbox-size);
            height: var(--checkbox-size);

            content: "";
            border-radius: 0.375rem;
            pointer-events: none;
            transition: all .2s ease;
        }

        &::after {
            background: no-repeat center;
            background-size: 50%;
        }
    }

    &-check.radio--checked {
        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        }
    }

    &--checked {
        * {
            color: white;
            transition: all 0s ease;
        }
        border-color: var(--colour-primary);
        background-color: var(--colour-primary);
    }

    &--unchecked {
        opacity: .5;
        * {
            transition: all 0s ease;
        }
    }
}

.radio__container {
    container-type: inline-size;

    .radio {
        @container (max-width: 540px) {
            width: 100%;
            max-width: 100%;
        }
    }
}
