.documents-list {
    display: grid;

    .table__item {
        display: grid;
        grid-template-columns: 250px 1fr 1fr;

        background-color: #fff;
        border: var(--default-border);
        border-radius: var(--default-border-radius);


        > div:first-child {
            padding-left: 1.5rem;

            &:has(input) {
                padding-left: 1rem;
            }
        }

        > div:last-child {
            padding-right: 1.5rem;

            &:has(input) {
                padding-right: 1rem;
            }
        }

        > div {
            padding: .5rem;

            &:has(input) {
                padding: .5rem;
            }
        }
    }

    .table__buttons {
        display: grid;
        grid-auto-flow: column;
        place-content: start;
        --table-button-colour: #12263f;
        height: 15px;

        svg {
            width: 15px;
            height: 15px;
            cursor: pointer;

            path {
                transition: all .1s ease;
                fill: var(--table-button-colour);
            }
        }
    }

    .table__button {
        &--delete {
            --table-button-colour: var(--colour-danger)
        }

        &--archive {
            --table-button-colour: var(--colour-info)
        }
    }
}

.documents-input {
    position: relative;
    display: grid;
    grid-auto-flow: row;
    place-content: center;

    padding: 1.5rem;

    border: var(--default-border);
    border-radius: var(--default-border-radius);
    background-color: #fff;
    text-align: center;

    span {
        color: #B1C2D9;
    }

     svg path {
         fill: #8c9aa9;
     }

    &--dragging {
        background-color: #eff8f6;
    }
}
