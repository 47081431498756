@import "breakpoints";

.search-container {
    position: absolute;
    z-index: 5;

    background-color: white;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    width: calc(100% - 2rem);
    margin-top: -1rem;

    max-height: 25rem;
    overflow-y: scroll;

    &.short {
        max-height: 10rem;
    }

    @media only screen and (max-width: $Mobile) {
        max-height: 17.5rem;

        &.short {
            max-height: 12.5rem;
        }
    }
}

.search-field {
    &:not(:placeholder-shown):not(:focus):invalid {
        background-color: #deebf2 !important;
    }
}

.search-item {
    padding: 0.5rem 0.75rem;
    cursor: pointer;

    &:hover {
        background-color: #f1f1f1;
    }
}

.disable-pointer-events {
    pointer-events: none;
}

.enable-pointer-events {
    pointer-events: auto;
}

.bottom-buffer {
    > div {
        margin-bottom: 3rem;

        @media only screen and (max-width: $Tablet) {
            margin-bottom: 1.5rem;
        }
    }

    > div:last-child {
        margin-bottom: 5rem;
    }
}

.job-body {
    text-align: left;

    h1, h2, h3, h4, h5, h6 {
        margin-top: 2.5rem;
    }

    li {
        margin: .5rem 0;
    }
}

.job-card {
    position: relative;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 64px;

    padding: .75rem;
    width: 340px;
    margin: 1.5rem auto;

    transition: all .25s ease;
    background-color: #FFFFFF;
    box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    border: 2px solid var(--colour-border);
    border-radius: 6px;

    text-align: left;
    cursor: pointer;

    h2 {
        grid-row: 1;

        max-height: 56px;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;

        @media only screen and (max-width: $Mobile) {
            max-height: 50px;
            font-size: 1.25rem;
        }
    }

    .j-card__body {
        p {
            margin: 1.5rem 0 0;

            @media only screen and (max-width: $Mobile) {
                margin: 1rem 0 0;
            }
        }

        .j-card__tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .j-card__tag {
            background-color: #eaeaeb;
            font-size: 12px;
            color: #464646;
            padding: 0 0.6rem;
            border-radius: 5px;
            margin: .8rem .4rem 0 0;
        }
    }

    > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin: 24px -.75rem -.75rem;
        background-color: var(--colour-border);

        text-align: center;
        font-size: 1.25rem;

        transition: all .25s ease;
        user-select: none;

        @media only screen and (max-width: $Mobile) {
            font-size: 1.1rem;
            margin-top: 16px;
        }
    }

    &:hover {
        border: 2px solid var(--colour-secondary);

        > div:last-child {
            background-color: var(--colour-secondary);
            color: white;
        }
    }

    @media only screen and (max-width: $Mobile) {
        width: 270px;
        margin: 1rem 0;
        grid-template-rows: auto auto 56px;
    }
}

.job-apply-button {
    position: fixed;
    bottom: 5rem;
    right: 0;

    font-size: 2rem;
    background-color: #63C263;
    transition: all .25s ease;
    color: white;
    cursor: pointer;
    text-align: center;
    line-height: 52px;
    border-radius: 6px 0 0 6px;
    user-select: none;

    padding: 0 2rem;
    max-width: 200px;
    height: 52px;

    &:hover {
        background-color: #80d280;
    }

    @media only screen and (max-width: $TabletSmall) {
        bottom: 3rem;
    }

    @media only screen and (max-width: $Mobile) {
        bottom: 0;
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        font-size: 1.5rem;
    }

    &.expired {
        background-color: #d96565;
    }
}

.job-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: fixed;
    width: 100%;
    z-index: 0;

    bottom: calc(1.5rem + 5px);
    transition: all .15s ease;

    @media only screen and (max-width: $Laptop) {
        bottom: calc(1rem);
    }

    @media only screen and (max-width: $TabletSmall) {
        bottom: 0;
    }

    > div, a, button {
        text-align: center;
        min-width: 256px;
        height: 62px;
        line-height: 48px;
        letter-spacing: 1px;
        font-weight: 300;
        font-size: 24px;
        font-family: var(--default-fonts);

        cursor: pointer;
        user-select: none;
        transition: all .15s ease;
        color: var(--colour-secondary);
        border: 2px solid var(--colour-secondary);

        &:first-child {
            border-radius: 6px 0 0 6px;
            border-right: 1px solid var(--colour-secondary);
            background-color: white;

            @media only screen and (min-width: $TabletSmall) {
                &:hover {
                    box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.25);
                }
            }
        }

        &:last-child {
            border-radius: 0 6px 6px 0;
            border-left: 1px solid var(--colour-secondary);
            color: white;
            background-color: var(--colour-secondary);

            @media only screen and (min-width: $TabletSmall) {
                &:hover {
                    box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.25);
                }
            }
        }

        &.single-button {
            border-radius: 6px;
        }

        @media only screen and (max-width: $Laptop) {
            height: 56px;
        }

        @media only screen and (max-width: $TabletSmall) {
            border: 0 solid transparent;
            border-top: 2px solid var(--colour-secondary);
            min-width: 50vw;
            height: 52px;
            font-size: 22px;

            &:first-child, &:last-child {
                border-radius: 0;
            }

            &.single-button {
                width: 100%;
                max-width: 100%;
            }
        }

        &.expired {
            border-color: #d96565;
            background-color: #d96565;
        }
    }
}

.documents-signer-card {
    &.disappear {
        animation-duration: .15s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: disappear;

        @keyframes disappear {
            0% {
                opacity: 1;
                transform: translateX(0);
            }
            100% {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }

    .remove-button {
        position: absolute;
        top: -16px;
        right: -16px;
        background: url("../../assets/icons/cancel.svg") no-repeat center;
        background-size: 100% 100%;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
}

.contact-modal-button {
    position: fixed;
    bottom: 0;
    right: 5rem;
    z-index: 10;

    background-color: #8577c3;
    border-radius: 5px 5px 0 0;
    padding: 12px 24px;

    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: white;
    text-align: center;

    cursor: pointer;

    img {
        width: 18px;
        transform: translateY(4px);
    }
}

.how-it-works {
    display: grid;
    grid-template-columns: repeat(3, calc(33% - .66rem));
    grid-gap: 1rem;
    margin-top: 4rem;
    text-align: center;
    margin-left: -2.5rem;
    margin-right: -2.5rem;

    @media only screen and (max-width: $Laptop) {
        margin: 3rem 0 2rem;
    }

    @media only screen and (max-width: $Mobile) {
        grid-template-rows: repeat(3, calc(33% - .66rem));
        grid-template-columns: repeat(1, 100%);
    }

    img {
        width: 51px;
        height: 51px;
        margin-bottom: 1.5rem;

        @media only screen and (max-width: $Tablet) {
            width: 2.5rem;
            height: 2.5rem;
            margin-bottom: .5rem;
        }

        //@media only screen and (max-width: $Mobile) {
        //    width: 1.5rem;
        //    height: 1.5rem;
        //}
    }

    p {
        font-weight: 400;
        margin: 0 0 1rem;

        @media only screen and (max-width: $Mobile) {
            font-weight: 300;
            //font-size: .8rem;
            //line-height: 1rem;
        }
    }
}

.services__header {
    font-size: 2rem;
    margin-bottom: 4rem;
    margin-top: -4rem;

    @media only screen and (max-width: $Tablet) {
        margin-top: 0;
    }
}

.services__item {
    display: grid;
    gap: 1rem;
    margin-bottom: 4rem;

    img {
        width: 100%;
    }

    > div {
        display: grid;
        place-content: center;
    }
}

.collapsable {
    //display: grid;
}

.collapsable, .collapsable--inline {
    //grid-template-rows: 0fr;
    //opacity: 0;
    //margin-bottom: -1px;
}

.collapsable--header {
    cursor: pointer;
    user-select: none;
}

.collapsable--inline {
    //display: inline-grid;

    .collapsable--header {
        display: inline-grid;
        place-content: center;
        padding: 0 1rem;

        color: var(--colour-link);
        font-size: 1rem;
    }
}

.collapsable--body {
    display: grid;
    grid-template-rows: 0fr;
    min-height: 0;
    padding: 0 .5rem;

    overflow: hidden;
    transition: all .2s ease;
    border: 1px solid transparent;
    border-radius: 5px;

    font-size: 1rem;
    font-weight: 300;
    font-family: var(--default-fonts);
    color: #282828;

    &--content {
        min-height: 0;

        img {
        }
    }

    &.open {
        grid-template-rows: 1fr;
        padding: .5rem;

        border: 1px solid var(--colour-border);
        background-color: var(--color-primary-background-light);
    }
}

.message__container {
    padding: .5rem;

    border: 1px solid var(--colour-border);
    background-color: var(--color-primary-background-light);
    border-radius: 5px;

    font-size: 1rem;
    font-weight: 300;
    font-family: var(--default-fonts);
    color: #282828;

    &--warning {
        background-color: var(--colour-yellow-background);
    }
}
