.collapsable--default {
    border: var(--default-border);
    border-color: var(--colour-light-grey);
    border-radius: 10px;
    background-color: var(--colour-light-grey);

    .collapsable__header {
        display: grid;
        grid-template-columns: 1fr min-content;
        text-align: left;
        padding: 0 1rem;

        .rotatable {
            display: grid;
            place-content: center;
            height: 100%;

            transform: rotateZ(180deg);
            transition: all .15s ease;
        }

        &.open {
            .rotatable {
                transform: rotateZ(0);
            }
        }
    }
}
