@import "breakpoints";

@mixin user__action--hover {
    pointer-events: auto;
    opacity: 1;
    top: 62px;
}

nav {
    z-index: 1000;
    position: fixed;
    top: 0;

    height: 5.5rem;
    padding: 1.5rem 0 1rem;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav {
    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 90%;
    }

    &__container {
        &--left {
            display: grid;
            grid-auto-flow: column;

            span {
                margin: auto;
            }
        }

        &--right {
            align-items: center;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            pointer-events: auto;

            a {
                font-family: "CircularStd", sans-serif;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.01em;
                text-transform: uppercase;

                color: #262626;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;

                margin: 0 1.5rem;

                &.call-to-action {
                    background-color: var(--colour-secondary);
                    padding: .5rem 1rem;
                    margin: 0 .5rem;
                    border-radius: 6px;
                    color: white;
                }

                &.highlight {
                    text-decoration: underline;
                }
            }
        }
    }

    &__logo {
        cursor: pointer;

        img {
            max-height: 56px;
            transition: max-height .25s ease;
            transform: translateY(-8px);
            content: url("/static/images/logo-default.svg");

            @media only screen and (max-width: $Mobile) {
                max-height: 38px;
                transform: translateY(-4px);
            }

            &.logo-crm {
                content: url("/static/images/logo-crm.svg");
            }
        }
    }

    &__user {
        display: none;
        user-select: none;
        pointer-events: none;
        cursor: pointer;
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
        border: 2px solid var(--colour-border);
        border-radius: 50%;
        flex-direction: column;
        justify-content: center;
        z-index: 1;

        &--container {
            position: relative;
            padding: 8px;

            &:hover {
                .nav__action {
                    @include user__action--hover();
                }
            }
        }

        &--logged-on {
            display: flex;
            pointer-events: auto;
        }

        &--name {
            text-align: center;
            font-size: 20px;
            transform: translate(0, 1px);
        }
    }

    &__action {
        pointer-events: none;
        position: absolute;
        background-color: #FFFFFF;
        transition: all .2s ease;
        border: 2px solid var(--colour-border);
        border-radius: 8px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;

        top: 45px;
        right: 8px;

        &:hover {
            @include user__action--hover();
        }

        div {
            padding: 6px 12px;
            transition: all .2s ease;
        }

        div:hover {
            background-color: #f1f1f1;
        }
    }
}
