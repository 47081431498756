.collapsable {
    --collapse-duration: .2s;

    z-index: 1;
    position: relative;
    transition: all .2s ease;
}

.collapsable__header {
    cursor: pointer;
    user-select: none;
}

.collapsable__body {
    display: grid;
    grid-template-rows: 0fr;
    padding: 0;

    overflow: hidden;
    transition: all var(--collapse-duration) ease;

    > div {
        min-height: 0;
        transition: all .2s ease,
                    background-color .5s ease;
    }

    &:not(.open) {
        //overflow: hidden;

        > * {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.open {
        grid-template-rows: 1fr;

        > div {
            transition: all .2s ease,
                        background-color .1s ease;
        }
    }
}

.collapsable--search-input {
    z-index: 2;

    .collapsable__body > div {
        position: absolute;
        width: 100%;
        max-height: 0;

        border-radius: .25rem;
        border: 1px solid transparent;
        background-color: transparent;
        overflow-y: scroll;
    }

    .collapsable__item {
        padding: 0.75rem .75rem;

        line-height: 1;
        cursor: pointer;

        &:hover {
            background-color: #f1f1f1;
        }
    }

    &.open {
        .collapsable__body > div {
            max-height: 450px;
            border: 1px solid var(--colour-border);
            background-color: #fff;
        }
    }
}
