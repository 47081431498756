.section__header {
    border-bottom: 1px solid #e3ebf6;

    h1 {
        color: var(--default-font-colour);
        font-size: 1.5rem;
        font-weight: 300;
    }
}

.section__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
    place-content: start;

    padding: 1rem 0;
}

.section__list--grid {
    display: grid;
    grid-auto-flow: row;

    overflow: hidden;
    border: 1px solid #edf2f9;
    border-radius: 0.5rem;
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
}
