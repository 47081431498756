.envelope__button {
    display: grid;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    height: 29.11px;
    width: 29.11px;

    border-radius: 4px;
    border: 1px var(--colour-border) solid;
    background-color: #e1e6ea;

    img {
        width: 25.11px;
        height: 25.11px;
        filter: invert(35%) sepia(0%) saturate(138%) hue-rotate(253deg) brightness(92%) contrast(95%);
    }
}

.envelope__buttons {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 .25rem;
}

.envelope__item {
    display: flex;
    height: 22px;
    line-height: 24px;
}

.envelope__miscellaneous {
    &--answer {
        font-weight: 300;

        img {
            margin-left: .5rem;
            width: 22px;
            height: 22px;
            filter: invert(35%) sepia(0%) saturate(138%) hue-rotate(253deg) brightness(92%) contrast(95%);
        }
    }
}

.envelope__signers {
    margin-bottom: .5rem;

    &--header {
        display: flex;

        height: 22px;
        line-height: 24px;
        font-size: 1rem;

        span {
            margin-left: .5rem;
        }
    }

    &--contact, &--status {
        position: relative;
        height: 22px;
        margin-left: 40px;
        --size-icon-default: 16px;

        span {
            display: flex;
            flex-direction: row;
            margin-left: 16px;
            font-size: .9rem;

            div {
                margin-right: .25rem;
            }
        }

        &::after {
            content: "";
            position: absolute;
            border-top: #777777 2px solid;
            width: 11px;
            top: calc(50% - 1px);
            left: 0;
        }
    }

    img {
        width: 22px;
        height: 22px;
        filter: invert(35%) sepia(0%) saturate(138%) hue-rotate(253deg) brightness(92%) contrast(95%);
    }

    &--contact {
        display: flex;

        border-left: #777777 2px solid;
        border-radius: 2px;

        img {
            margin-left: 12px;
            width: 18px;
            height: 18px;
        }

        &-phone, &-email {
            display: grid;
            align-content: center;
            font-size: .9rem;
        }
    }

    &--status {
        &::before {
            content: "";
            position: absolute;
            border-left: #777777 2px solid;
            height: 11px;
        }

        &::after {
            width: 13px;
        }
    }
}

.envelope__documents {
    margin: .25rem 0;
    display: flex;
    flex-direction: row;

    &--name {
        min-width: calc(100% - 33.11px);
        max-width: calc(100% - 33.11px);
        padding: .15rem .25rem;
        overflow: hidden;

        font-size: .9rem;
        text-overflow: ellipsis;

        border: 1px var(--colour-border) solid;
        border-radius: 4px;
        background-color: #e1e6ea;
    }
}
